import { motion } from "framer-motion";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { config_url } from "../../config";

// Helper function to process sales data by day
const processSalesData = (sales) => {
  const dailySalesMap = {};

  sales.forEach((sale) => {
    const date = moment(sale.date_order).format("YYYY-MM-DD");
    if (dailySalesMap[date]) {
      dailySalesMap[date].sales += sale.total_sales_amount;
    } else {
      dailySalesMap[date] = {
        date,
        sales: sale.total_sales_amount,
      };
    }
  });

  return Object.values(dailySalesMap);
};

const DailySalesTrend = () => {
  const [dailySalesData, setDailySalesData] = useState([]);

  useEffect(() => {
    axios
      .get(`${config_url}/api/sales`)
      .then((response) => {
        const processedData = processSalesData(response.data);
        setDailySalesData(processedData);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
      });
  }, []);

  return (
    <motion.div
      className="bg-gray-800 bg-opacity-50 backdrop-blur-md shadow-lg rounded-xl p-6 border border-gray-700"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
    >
      <h2 className="text-xl font-semibold text-gray-100 mb-4">
        Daily Sales Trend
      </h2>

      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <BarChart data={dailySalesData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis dataKey="date" stroke="#9CA3AF" />
            <YAxis stroke="#9CA3AF" />
            <Tooltip
              contentStyle={{
                backgroundColor: "rgba(31, 41, 55, 0.8)",
                borderColor: "#4B5563",
              }}
              itemStyle={{ color: "#E5E7EB" }}
            />
            <Bar dataKey="sales" fill="#10B981" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </motion.div>
  );
};

export default DailySalesTrend;
