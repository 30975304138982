import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { detailsProduct } from "../../slices/detailsProduct";
import ProductCard from "../../components/product-card/ProductCard";
import GridLoader from "react-spinners/GridLoader";
import { config_url } from "../../config";

import { useTranslation } from "react-i18next";

import "./menu-pack.css";
import Navbar from "../../components/Navbar/Navbar";

const Category = () => {
  const Details = useSelector((state) => state.Load);
  const { Col } = Details;
  const [tables, setTables] = useState([]);
  const [open, setOpen] = useState(false);
  const [block, setBlock] = useState(false);
  const [listFiltred, setListFiltred] = useState(Col);
  const [filtered, setFiltered] = useState(
    localStorage.getItem("categorie_filtered") || ""
  );
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const override = {
    display: "block",
    margin: "50% 35%",
    borderColor: "red",
  };

  const dispatch = useDispatch();

  const filterItems = (category) => {
    setFiltered(category);
    if (category === "all") {
      setListFiltred(Col);
    } else if (category === "promo") {
      const newItems = Col.filter((item) => item.prix_promo !== 0);
      setListFiltred(newItems);
      return;
    } else if (category === "enfant") {
      const newItems = Col.filter((item) => item.enfant === 1);
      setListFiltred(newItems);
      return;
    }
    const newItems = Col.filter((item) => item.category === category);
    setListFiltred(newItems);
  };

  useEffect(() => {
    if (localStorage.getItem("categorie_filtered") === "all") {
      setListFiltred(Col);
      return;
    } else if (localStorage.getItem("categorie_filtered")) {
      const newItems = Col.filter(
        (item) => item.category === localStorage.getItem("categorie_filtered")
      );
      setListFiltred(newItems);
    }
  }, []);

  useEffect(() => {
    axios.get(`${config_url}/api/tables`).then((res) => {
      setTables(res.data);
    });
  }, []);

  return (
    <Fragment>
      <Navbar />

      <div>
        <div className="w-full inline-block justify-center items-center text-center mb-5 mt-10">
          <button
            key="1"
            className={`filter-btn ${filtered === "all" ? "active__btn" : ""}`}
            onClick={() => filterItems("all")}
          >
            {t("Menu.button-choise-all")}
          </button>
          <button
            key="2"
            className={`filter-btn ${
              filtered === "entree-salade" ? "active__btn" : ""
            }`}
            onClick={() => filterItems("entree-salade")}
          >
            {t("Menu.button-choise-entree")}
          </button>
          <button
            key="3"
            className={`filter-btn ${
              filtered === "plat-grille" ? "active__btn" : ""
            }`}
            onClick={() => filterItems("plat-grille")}
          >
            {t("Menu.button-choise-plat-grille")}
          </button>
          <button
            key="4"
            className={`filter-btn ${
              filtered === "plat-saute" ? "active__btn" : ""
            }`}
            onClick={() => filterItems("plat-saute")}
          >
            {t("Menu.button-choise-plat-saute")}
          </button>
          <button
            key="5"
            className={`filter-btn ${
              filtered === "plat-frit" ? "active__btn" : ""
            }`}
            onClick={() => filterItems("plat-frit")}
          >
            {t("Menu.button-choise-plat-frit")}
          </button>
          <button
            key="6"
            className={`filter-btn ${
              filtered === "flan-dissert" ? "active__btn" : ""
            }`}
            onClick={() => filterItems("flan-dissert")}
          >
            {t("Menu.button-choise-dissert")}
          </button>
          <button
            key="7"
            className={`filter-btn ${
              filtered === "boisson-jus" ? "active__btn" : ""
            }`}
            onClick={() => filterItems("boisson-jus")}
          >
            {t("Menu.button-choise-drinks")}
          </button>
          {/* <button
            key="8"
            className={`filter-btn ${
              filtered === "enfant" ? "active__btn" : ""
            }`}
            onClick={() => filterItems("enfant")}
          >
            {t("Menu.button-choise-menu-enfant")}
          </button> */}
          {/* <button
            key="8"
            className={`filter-btn ${
              filtered === "promo" ? "active__btn" : ""
            }`}
            onClick={() => filterItems("promo")}
          >
            <div className="flex items-center justify-center">
              <span>{t("Menu.button-choise-promo")}</span>
            </div>
          </button> */}
        </div>
        {/* Display Products */}
        <div className="mb-[50%] flex flex-wrap text-red-500">
          {listFiltred.map((item) => (
            <ProductCard key={item.id} item={item} tables={tables} />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Category;
