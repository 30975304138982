import React, { Fragment, useState, useEffect } from "react";
import { MdCloudUpload, MdDelete, MdCreate, MdAdd } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SelectOpt from "react-select";

import axios from "axios";
import { config_url } from "../../config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll", // This will add a vertical scrollbar
};
const options = [
  { value: "foodWaste", label: "Food Waste" },
  { value: "theft", label: "سرقة" },
  { value: "poorInventoryManagement", label: "سوء إدارة المخزون" },
  { value: "spoilage", label: "إفساد" },
  { value: "inefficientOrdering", label: "الطلب غير الفعّال" },
  { value: "menuDesign", label: "Menu Design" },
  { value: "other", label: "Other" },
];
function UpdProblem({ closeModal, rowProblem }) {
  const [problemName, setProblemName] = useState(rowProblem.problem);
  const [qtyLosed, setQtyLosed] = useState(rowProblem.qty_losed);
  const [qtyTrouver, setQtyTrouver] = useState(rowProblem.quantity_trouver);

  const handleChange = (selected) => {
    setProblemName(selected.label);
  };

  const handleSubmit = () => {
    const updatedProblem = {
      problem: problemName,
      qty_losed: qtyLosed,
      quantity_trouver: qtyTrouver,
    };

    axios
      .put(`${config_url}/api/problem/${rowProblem.problem_id}`, updatedProblem)
      .then((response) => {
        toast.success(response.data.message);
        closeModal(); // Close modal after update
      })
      .catch((error) => {
        toast.error("Error updating problem");
        console.error("Error:", error);
      });
  };

  return (
    <Fragment>
      <Modal
        open={true} // Modal is controlled by parent component
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-full bg-gray-100 p-2 mb-4">
            <Typography variant="h5" gutterBottom>
              Edit Problem {rowProblem.problem}
            </Typography>
          </div>
          <Divider />
          <div className="mt-4 flex items-center justify-center justify-between">
            <Typography variant="h5" gutterBottom>
              {problemName}
            </Typography>
            <div className="mt-4 mb-4">
              <TextField
                label="Qty Losed"
                variant="outlined"
                value={qtyLosed}
                onChange={(e) => setQtyLosed(e.target.value)}
              />
            </div>
            <div className="mt-4 mb-4">
              <TextField
                label="Qty Trouver"
                value={qtyTrouver}
                onChange={(e) => setQtyTrouver(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-10 flex flex-col items-center justify-center gap-4">
            <Typography variant="h5" gutterBottom>
              Selectez Problem de Perte
            </Typography>
            <div className="w-[100%]">
              <SelectOpt
                name="problems"
                options={options}
                classNamePrefix="select"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="right-0 mt-4 flex">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<MdCreate />}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default UpdProblem;
