import { useState, useEffect } from "react";
import axios from "axios";
import { config_url } from "../../config";
import { toast } from "react-toastify";
import { RiContractLine } from "react-icons/ri";
import Button from "@mui/material/Button";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Column } from "../../components/Column";

function PriorityProd() {
  const [oldlistProds, setOldListProds] = useState([]);
  const [listProds, setListProds] = useState([]);
  const [rangedProds, setRangedProds] = useState([]);

  useEffect(() => {
    axios
      .get(`${config_url}/api/products`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        setListProds(res.data);
        setOldListProds(res.data);
      });
  }, []);

  useEffect(() => {
    if (listProds.length && oldlistProds.length) {
      const arranged_data = listProds.map((prod, index) => ({
        id: prod.id,
        priority: oldlistProds[index].priority,
      }));
      setRangedProds(arranged_data);
      console.log(arranged_data);
    }
  }, [listProds, oldlistProds]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getProductPos = (id) => listProds.findIndex((prod) => prod.id === id);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    setListProds((listProds) => {
      const originalPos = getProductPos(active.id);
      const newPos = getProductPos(over.id);

      return arrayMove(listProds, originalPos, newPos);
    });
  };

  const handleChangePriority = () => {
    console.log(rangedProds);
    axios
      .put(`${config_url}/api/priority_products`, JSON.stringify(rangedProds), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Priority Change Success!", {
          position: "top-right",
        });
      });
  };

  return (
    <div className="mt-[150px] flex flex-col items-center">
      <div className="mb-8">
        <Button
          variant="outlined"
          startIcon={<RiContractLine />}
          onClick={handleChangePriority}
        >
          Save Priority
        </Button>
      </div>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
      >
        <Column id="toDo" listProds={listProds} />
      </DndContext>
    </div>
  );
}

export default PriorityProd;
