import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { loadLangue } from "../../slices/detailsProduct";
import i18next from "i18next";
import { Link } from "react-router-dom";

const settings = [
  { id: 1, langue: "Arabic", image: "/images/maroc.jpeg", value: "ar" },
  { id: 2, langue: "Deutsch", image: "/images/deutch.png", value: "de" },
  { id: 3, langue: "English", image: "/images/usa.png", value: "en" },
  { id: 4, langue: "Español", image: "/images/spain.png", value: "es" },
  { id: 5, langue: "Française", image: "/images/france.png", value: "fr" },
  {
    id: 6,
    langue: "Nederlands",
    image: "/images/netherlands.png",
    value: "nl",
  },
];

function Navbar() {
  const details = useSelector((state) => state.Load);
  const { Langue } = details;
  const [anchorElUser, setAnchorElUser] = useState(null);
  const dispatch = useDispatch();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClick = (lang) => {
    dispatch(
      loadLangue({ flag: lang.image, name: lang.langue, lang: lang.value })
    );
    i18next.changeLanguage(lang.value);
  };

  return (
    <AppBar className="rounded-3xl" position="static">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 1,
          }}
        >
          <Link to="/">
            <img
              src="/DON-CALAMAR.jpg"
              className="w-12 h-12 rounded-full"
              alt="Logo"
            />
          </Link>

          {/* Box for small screens */}

          <Box
            sx={{
              flexGrow: 0,
            }}
          >
            <Tooltip title="Open Languages">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Language Flag" src={Langue.flag} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.id}
                  onClick={() => {
                    handleCloseUserMenu();
                    handleClick(setting);
                  }}
                >
                  <Box className="flex items-center justify-between">
                    <img
                      className="h-5 w-5 rounded-full"
                      src={setting.image}
                      alt={setting.langue}
                    />
                    <Typography textAlign="center">{setting.langue}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
