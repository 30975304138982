import { motion } from "framer-motion";
import Header from "../../components/common/Header";
import StatCard from "../../components/common/StatCard";
import {
  Circle,
  CreditCard,
  DollarSign,
  GlassWater,
  ShoppingCart,
  TrendingUp,
} from "lucide-react";
import { Box, Grid } from "@mui/material";

import SalesOverviewChart from "../../components/sales/SalesOverviewChart";
import SalesByCategoryChart from "../../components/sales/SalesByCategoryChart";
import DailySalesTrend from "../../components/sales/DailySalesTrend";
import { useState, useEffect } from "react";
import axios from "axios";
import TimeOfOrdersChart from "../../components/sales/TimeOfOrdersChart";
import { config_url } from "../../config";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const SalesPage = () => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Returns 'YYYY-MM-DD'
  };

  const [listSales, setListSales] = useState({});
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState("Last Week"); // Default to "Current Day"
  const [salesData, setSalesData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);

  const [hasData, setHasData] = useState(false);

  // Fetch Sales Data
  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get(
          `${config_url}/api/sales/all/sales-overview`,
          {
            params: {
              timeRange:
                selectedTimeRange === "Current Day"
                  ? getCurrentDate()
                  : selectedTimeRange,
            },
          }
        );

        if (response.data && response.data.length > 0) {
          setSalesData(response.data);
          setHasData(true);
          // Set bar chart data based on the sales data received
          setBarChartData(transformToBarChartData(response.data));
        } else {
          setSalesData([defaultSalesData]); // Set default data if no sales data
          setHasData(false);
          setBarChartData([]); // Clear bar chart data
        }
      } catch (error) {
        console.error("Error fetching sales data:", error);
        setHasData(false);
        setBarChartData([]); // Clear bar chart data
      }
    };

    fetchSalesData();
  }, [selectedTimeRange]);
  // Transform function for bar chart data
  const transformToBarChartData = (salesData) => {
    console.log(salesData);
    return salesData.map((day) => {
      // Extracting relevant department data for the bar chart
      const friteuseValue =
        day.departmentData.find((item) => item.name === "friteuse")?.value || 0;
      const planchaValue =
        day.departmentData.find((item) => item.name === "plancha")?.value || 0;
      const saladeValue =
        day.departmentData.find((item) => item.name === "saladre")?.value || 0;
      const jusValue =
        day.departmentData.find((item) => item.name === "pret")?.value || 0;
      return {
        date: day.date_order,
        Friteuse: friteuseValue, // Combined value for this category
        Plancha: planchaValue,
        Salade: saladeValue,
        Jus: jusValue,
      };
    });
  };
  const defaultSalesData = {
    total_orders: 0,
    total_sales_amount: 0,
    sales_friteuse_plancha_salade: 0,
    sales_jus: 0,
    date_order: getCurrentDate(),
  };

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(
          `${config_url}/api/sales/all/sales-overview/current-date`
        );
        setListSales(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchStatistics();
  }, []);
  // Recharts data formatting
  const formattedSalesData = salesData.map((day) => ({
    date: day.date_order,
    Friteuse_Plancha_Salade: day.sales_friteuse_plancha_salade || 0,
    Jus: day.sales_jus || 0,
  }));

  if (error) return <div>Error: {error.message}</div>;
  if (!listSales) return <div>Loading...</div>;

  return (
    <div className="flex-1 overflow-auto relative z-10">
      <Header title="Sales Dashboard" />

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        <div>
          {/* SALES STATS */}
          <motion.div
            className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <StatCard
              name="Total Revenue"
              icon={DollarSign}
              value={listSales.totalSales}
              color="#6366F1"
            />
            <StatCard
              name="Total Orders"
              icon={ShoppingCart}
              value={listSales.totalOrders}
              color="#10B981"
            />
            <StatCard
              name="Total Plats Sortie Dh"
              icon={Circle}
              value={listSales.fpsSales}
              color="#F59E0B"
            />
            <StatCard
              name="TOtal Boissons Et Desserte sortie Dh"
              icon={GlassWater}
              value={listSales.jusSales}
              color="#EF4444"
            />
          </motion.div>
          <Grid item xs={12} md={6}>
            <h3>Orders by Department</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={listSales?.departmentData}
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  label
                >
                  {listSales?.departmentData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <TimeOfOrdersChart />

          <div className="flex items-center justify-between mb-6 mt-10">
            <h2 className="text-xl font-semibold text-black-100">
              Sales Overview
            </h2>

            <select
              className="bg-gray-700 text-white rounded-md px-3 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={selectedTimeRange}
              onChange={(e) => setSelectedTimeRange(e.target.value)}
            >
              <option value="Last Week">Last Week</option>
              <option value="This Month">This Month</option>
              <option value="This Quarter">This Quarter</option>
              <option value="This Year">This Year</option>
            </select>
          </div>

          <div className="sales-overview">
            {(hasData ? salesData : [defaultSalesData]).map((day, index) => (
              <div
                key={index}
                className="border p-4 mb-4 rounded bg-white shadow"
              >
                <h3 className="text-lg font-semibold mb-2">
                  Date: {day.date_order}
                </h3>
                <p>Total Orders: {day.total_orders}</p>
                <p>Total Sales: {(day.total_sales_amount || 0).toFixed(2)}Dh</p>
                <p>
                  Friteuse/Plancha/Saladre Sales:
                  {(day.sales_friteuse_plancha_salade || 0).toFixed(2)}Dh
                </p>
                <p>
                  Jus And Dissert Sales: {(day.sales_jus || 0).toFixed(2)}Dh
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Sales by Category Bar Chart */}
        <div className="my-6">
          <h2 className="text-xl font-semibold text-black-100">
            Sales by Category
          </h2>
          <BarChart
            width={600}
            height={400}
            data={formattedSalesData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Friteuse_Plancha_Salade" fill="#8884d8" />
            <Bar dataKey="Jus" fill="#82ca9d" />
          </BarChart>
        </div>
        <SalesOverviewChart />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
          <DailySalesTrend />
        </div>
        {/* Bar Chart */}
        <div className="my-6">
          <h2 className="text-xl font-semibold text-black-100">
            Sales by Category
          </h2>
          <BarChart
            width={600}
            height={400}
            data={barChartData} // Use the transformed bar chart data
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Friteuse" fill="#8884d8" />
            <Bar dataKey="Plancha" fill="#FF6500" />
            <Bar dataKey="Salade" fill="#133E87" />

            <Bar dataKey="Jus" fill="#82ca9d" />
          </BarChart>
        </div>
      </main>
    </div>
  );
};

export default SalesPage;
