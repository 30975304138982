import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
const CartEmpty = ({ onClose }) => {
  return (
    <>
      <div className="flex items-center flex-col h-screen text-center gap-7">
        <img
          src="/emptybag.png"
          alt="emptybag/img"
          className="w-40 lg:w-36 sm:w-28 h-auto object-fill transition-all duration-300 hover:scale-110"
        />
        <Link to="/tables">
          <button
            type="button"
            onClick={() => onClose(false)}
            className="button-theme bg-gradient-to-b from-amber-500 to-orange-500 shadow-lg shadow-orange-500 flex items-center justify-center text-slate-900 py-2 gap-3 text-sm px-5 font-semibold active:scale-110"
          >
            <ArrowLeftIcon className="w-5 h-5 text-slate-900" />
            <span>Retour Pour Comander Plus</span>
          </button>
        </Link>
      </div>
    </>
  );
};

export default CartEmpty;
