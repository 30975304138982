import { motion } from "framer-motion";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { config_url } from "../../config";

// Helper function to process order data by time of day
const processOrderData = (orders) => {
  const timeCategories = {
    Morning: { label: "Morning", count: 0 },
    Afternoon: { label: "Afternoon", count: 0 },
    Night: { label: "Night", count: 0 },
  };

  orders.forEach((order) => {
    const hour = moment(order.date_order, "YYYY-MM-DD HH:mm").hour();
    if (hour >= 5 && hour < 12) {
      timeCategories.Morning.count++;
    } else if (hour >= 12 && hour < 17) {
      timeCategories.Afternoon.count++;
    } else {
      timeCategories.Night.count++;
    }
  });

  return Object.values(timeCategories);
};

const TimeOfOrdersChart = () => {
  const [orderData, setOrderData] = useState([]);

  useEffect(() => {
    axios
      .get(`${config_url}/api/time-orders/statistics`)
      .then((response) => {
        const processedData = processOrderData(response.data.orders);
        setOrderData(response.data.orders);
      })
      .catch((error) => {
        console.error("Error fetching order data:", error);
      });
  }, []);

  return (
    <motion.div
      className="bg-gray-800 bg-opacity-50 backdrop-blur-md shadow-lg rounded-xl p-6 border border-gray-700"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
    >
      <h2 className="text-xl font-semibold text-gray-100 mb-4">
        Time of Orders
      </h2>

      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <BarChart data={orderData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis dataKey="label" stroke="#9CA3AF" />
            <YAxis stroke="#9CA3AF" />
            <Tooltip
              contentStyle={{
                backgroundColor: "rgba(31, 41, 55, 0.8)",
                borderColor: "#4B5563",
              }}
              itemStyle={{ color: "#E5E7EB" }}
            />
            <Bar dataKey="count" fill="#FF0000" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </motion.div>
  );
};

export default TimeOfOrdersChart;
