import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa"; // Import react-icon for dropdown
import "./Nav.modules.css";
import { useSelector } from "react-redux";

const Nav = ({ children }) => {
  const { User } = useSelector((state) => state.userInfo);
  const [show, setShow] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false); // For handling dropdown
  const navigate = useNavigate();

  const showSideBar = () => {
    setShow(!show);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen); // Toggle dropdown visibility
  };

  const Logout = async () => {
    await localStorage.removeItem("token");
    await navigate("/login");
  };

  const links = [
    {
      url: "/app/list-produits",
      i_class: "bx bxs-sushi",
      link_title: "Products",
      key: 1,
    },
    {
      url: "/app/list-plats",
      i_class: "bx bxs-analyse",
      link_title: "Plats",
      key: 2,
    },
    {
      url: "/app/produit-defauts",
      i_class: "bx bx-circle-three-quarter",
      link_title: "Produit Default",
      key: 3,
    },
    {
      url: "utilisateurs",
      i_class: "bx bxs-user ",
      link_title: "Users",
      key: 4,
    },

    // {
    //   url: "/app/list-categories",
    //   i_class: "bx bx-purchase-tag-alt",
    //   link_title: "Categories",
    //   key: 4,
    // },
    {
      url: "/app/hiden-produits",
      i_class: "bx bxs-low-vision",
      link_title: "Hidden Products",
      key: 5,
    },
  ];

  return (
    <>
      <header
        className={`header ${show ? "add_body_padding" : ""}`}
        id="admin-dash-header"
      >
        <div className="header_toggle">
          <i
            className={`bx bx-menu ${show ? "bx-x" : ""}`}
            id="header-toggle"
            onClick={showSideBar}
          ></i>
        </div>
        <div className="dropdown sidebar-profile">
          <span className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none">
            <img
              src="https://img.icons8.com/color/48/000000/circled-user-male-skin-type-4--v1.png"
              alt="avatar"
              className="avatar rounded-circle"
            />
          </span>
        </div>
      </header>

      <aside className={`sidebar ${show ? "review" : ""}`} id="admin-dash-nav">
        <nav className="admin-dash-nav">
          <div>
            <div className="nav_logo">
              <img src="/DON-CALAMAR.jpg" alt="logo" className="logo" />
            </div>
            <div className="nav_list">
              {User.role === "Fornisseur" ? (
                <NavLink to="/app/fornisseur" className="nav_link">
                  <i className="bx bxs-user nav_icon"></i>
                  <span className="nav_name">Fornisseur</span>
                </NavLink>
              ) : (
                <>
                  {links.map((link) => (
                    <NavLink to={link.url} className="nav_link" key={link.key}>
                      <i className={`${link.i_class} nav_icon`}></i>
                      <span className="nav_name">{link.link_title}</span>
                    </NavLink>
                  ))}

                  {/* Dropdown for Statistics */}
                  <div className="dropdown-container">
                    <button
                      className="dropdown-toggle nav_link"
                      onClick={toggleDropdown}
                    >
                      <i className="bx bx-bar-chart-alt-2 nav_icon"></i>
                      <span className="nav_name">Statistics</span>
                      <FaChevronDown
                        className={`dropdown-icon ml-10 mr-10${
                          dropdownOpen ? "rotate" : ""
                        }`}
                      />
                    </button>
                    {dropdownOpen && (
                      <ul className="dropdown-menu">
                        <li>
                          <NavLink
                            to="/app/list-orders"
                            className="dropdown-item"
                          >
                            <i className="bx bx-cart-add"></i>
                            Orders
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/app/statistics/sales"
                            className="dropdown-item"
                          >
                            <i className="bx bx-money-withdraw"></i>
                            Sales
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </div>
                </>
              )}
            </div>
            <span className="mt-20 nav_link" onClick={Logout}>
              <i className="bx bx-log-out bx-sm nav_icon"></i>
              <span className="nav_name">SignOut</span>
            </span>
          </div>
        </nav>
      </aside>

      <main className={`${show ? "add_body_padding" : "main"}`}>
        {children}
      </main>
    </>
  );
};

export default Nav;
