import React from "react";
import "./withcommande.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { config_url } from "../../config";
import { loadDetailsProduct } from "../../slices/detailsProduct";
function WithCommanded(props) {
  const det = useSelector((state) => state.Load);
  const { Langue } = det;

  const { name, image, prix, prix_promo } = props.item;
  const propertyName = name + "_" + Langue.lang;
  const valuefr = props.item[propertyName];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const details = (dts) => {
    console.log(dts);
    dispatch(loadDetailsProduct(dts));
    navigate("/Pos/menu/category/details");
  };

  const { t } = useTranslation();

  return (
    <div className="card_products_comm">
      <div className="single__product_comm" onClick={() => details(props.item)}>
        <div className="product__img_comm">
          <img src={`${config_url}/images/${image}`} alt={valuefr} />
        </div>

        <div className="product__content_comm">
          <h4 className="w-full mb-2 mt-2">{valuefr}</h4>
          {prix_promo === 0 ? (
            <div className="flex items-center justify-between inset-x-0 bottom-0">
              <span className="price flex items-center justify-between">
                <span>{t("Card.prix")}:</span>{" "}
                <span className="ml-1 font-bold text-xl">{prix}DH</span>
              </span>
            </div>
          ) : (
            <div className="items-center text-center">
              <div className="flex items-center justify-between inset-x-0 bottom-0">
                <span className="text-[#EAE0DA] text-xl font-bold flex items-center ml-10">
                  {t("Card.prix")}: <span className="promotion"> {prix}DH</span>
                </span>
              </div>
              <div className="flex items-center justify-between inset-x-0 bottom-0">
                <span className="text-white text-center">
                  {t("Card.prix_promo")}:
                  <span className="text-white font-bold text-xl">
                    {prix_promo} DH
                  </span>
                </span>
              </div>
            </div>
          )}
          <button
            className="product__detail_comm"
            onClick={() => details(props.item)}
          >
            <span>{t("Card.button-detail")}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default WithCommanded;
