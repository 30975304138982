import React, { useEffect, useState, useCallback, Fragment } from "react";
import io from "socket.io-client";
import { config_url } from "../../config";
import { getOrders } from "../../services/api";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Invoice from "../../components/Invoice";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { AiFillCarryOut } from "react-icons/ai";
import { MdAutorenew, MdCreditScore, MdAccessTime } from "react-icons/md";
import _ from "lodash"; // Import lodash for debounce
import ModelUpdTable from "../../components/ModelUpdTable";

const socket = io(`${config_url}`);

function Controlle() {
  const [orders, setOrders] = useState([]);
  console.log(orders);

  const [invoiceShow, setInvoiceShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableNum, setTableNum] = useState(1);
  const [orderNum, setOrderNum] = useState(1);

  const [rowOrders, setRowOrders] = useState({});
  const [currentTime, setCurrentTime] = useState(new Date()); // State for current time

  const [filteredOrders, setFilteredOrders] = useState([]);
  console.log(filteredOrders);
  const [category, setCategory] = useState("Tous");
  const [status, setStatus] = useState("Tous");

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    socket.emit("join-department", "controlle");

    socket.on("new-order", (order) => {
      setOrders((prevOrders) => [...prevOrders, order]);
    });

    socket.on("all-orders-updated", (allOrders) => {
      setOrders(allOrders);
      setFilteredOrders(allOrders); // Ensure filteredOrders updates on allOrders update
    });

    const fetchOrders = async () => {
      try {
        const response = await getOrders();
        setOrders(response.data.orders);
        setFilteredOrders(response.data.orders);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrders();

    return () => {
      socket.off("new-order");
      socket.off("all-orders-updated");
    };
  }, []);
  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 6000); // Update every 60 seconds
    return () => clearInterval(timer); // Cleanup timer on unmount
  }, []);
  const handleSearch = useCallback(
    _.debounce((term) => {
      filterOrders(category, status, term); // Pass all criteria
    }, 300),
    [category, status] // These need to be in the dependency array
  );

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, handleSearch]);
  useEffect(() => {
    const debounceFilter = _.debounce(() => {
      filterOrders(category, status, searchTerm);
    }, 300);

    debounceFilter();

    return () => debounceFilter.cancel();
  }, [category, status, searchTerm, orders]);
  // Function to calculate the difference in minutes between the order time and current time
  const calculateTimeDifference = (orderTime) => {
    const orderDate = new Date(orderTime);
    const timeDiff = Math.abs(currentTime - orderDate);
    const minutes = Math.floor(timeDiff / 1000 / 60); // Only calculate minutes
    return `${minutes} minutes ago`;
  };

  const openModal = (row) => {
    setIsModalOpen(true);
    setTableNum(row.table_num);
    setOrderNum(row.order_num);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showInvoice = (rowOrder) => {
    setInvoiceShow(true);
    setRowOrders(rowOrder);
  };

  const closeInvoice = () => {
    setInvoiceShow(false);
  };

  //Filters
  // Unified filter
  const filterOrders = (selectedCategory, selectedStatus, searchTerm) => {
    let newFilteredOrders = [...orders];

    if (selectedCategory !== "Tous") {
      newFilteredOrders = newFilteredOrders.filter(
        (bon) => bon.department.toLowerCase() === selectedCategory.toLowerCase()
      );
    }

    if (selectedStatus !== "Tous") {
      newFilteredOrders = newFilteredOrders.filter(
        (bon) => bon.status.toLowerCase() === selectedStatus.toLowerCase()
      );
    }

    if (searchTerm) {
      newFilteredOrders = newFilteredOrders.filter((order) => {
        const orderTable = String(order.table_num); // Ensure table is a string
        return orderTable.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    setFilteredOrders(newFilteredOrders);
  };

  // Filter by category
  const filterCategory = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    filterOrders(selectedCategory, status); // Pass both selected category and status
  };

  // Filter by status
  const filterStatus = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
    filterOrders(category, selectedStatus); // Pass both selected category and status
  };

  const cancelFiltered = () => {
    setCategory("Tous"); // Reset category
    setStatus("Tous"); // Reset status
    setSearchTerm(""); // Reset search term
    setFilteredOrders(orders); // Reset to the original orders list
  };
  const getStatusStyles = (status) => {
    switch (status) {
      case "pending":
        return {
          backgroundColor: "rgba(255, 165, 0, 0.2)", // Orange background
          color: "orange",
        };
      case "en-cours":
        return {
          backgroundColor: "rgba(0, 123, 255, 0.2)", // Blue background
          color: "blue",
        };
      case "dressing":
        return {
          backgroundColor: "rgba(255, 0, 0, 0.2)", // Red background
          color: "red",
          animation: "dressingBlink 1.5s infinite", // Apply blinking animation
        };
      case "pret":
        return {
          backgroundColor: "rgba(0, 128, 0, 0.2)", // Green background
          color: "green",
        };
      default:
        return {
          backgroundColor: "rgba(0, 0, 0, 0.2)", // Default background
          color: "black",
        };
    }
  };
  const fetchOldOrders = () => {
    const fetchOrders = async () => {
      try {
        const response = await getOrders();
        setOrders(response.data.orders);
        setFilteredOrders(response.data.orders);
      } catch (error) {
        console.error(error);
      }
    };
    fetchOrders();
  };
  return (
    <Fragment>
      {isModalOpen && (
        <ModelUpdTable
          closeModal={closeModal}
          setTableNum={setTableNum}
          tableNum={tableNum}
          orderNum={orderNum}
        />
      )}{" "}
      <div className="flex min-h-screen items-center justify-center bg-white">
        <div className="p-6 overflow-scroll px-0">
          <div className="flex items-center justify-center gap-4">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="Search Tables"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            <div className="w-90 px-3 mb-10 md:mb-0">
              <Box sx={{ ml: 2, mb: 4, minWidth: 220 }}>
                <FormControl sx={{ minWidth: 220 }}>
                  <label className="mt-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Departement:
                  </label>{" "}
                  <Select
                    value={category}
                    onChange={filterCategory}
                    sx={{ backgroundColor: "#fff" }}
                  >
                    <MenuItem value="Tous">Tous</MenuItem>

                    <MenuItem value="plancha">Plancha</MenuItem>
                    <MenuItem value="friteuse">Friteuse</MenuItem>
                    <MenuItem value="saladre">Saladre</MenuItem>
                    <MenuItem value="pret">Pret</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className="w-90 px-3 mb-10 md:mb-0">
              <Box sx={{ ml: 2, mb: 4, minWidth: 220 }}>
                <FormControl sx={{ minWidth: 220 }}>
                  <label className="mt-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Status:
                  </label>{" "}
                  <Select
                    value={status}
                    onChange={filterStatus}
                    sx={{ backgroundColor: "#fff" }}
                  >
                    <MenuItem value="Tous">Tous</MenuItem>

                    <MenuItem value="pending">En-Attente</MenuItem>
                    <MenuItem value="en-cours">En-Cours</MenuItem>
                    <MenuItem value="dressing">Dressing</MenuItem>
                    <MenuItem value="pret">Pret</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded"
              onClick={() => cancelFiltered()}
            >
              Cancel Filters
            </button>
            <Button
              variant="outlined"
              endIcon={<MdAutorenew />}
              onClick={() => fetchOldOrders()}
            >
              Load History
            </Button>
          </div>

          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Order Num
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Table
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Order Time
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Status
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Time Difference
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Order Details
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Departement
                  </p>
                </th>
                <th className="flex items-center justify-center border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Modifications
                  </p>
                </th>
              </tr>
            </thead>
            <tbody className="text-2xl">
              {filteredOrders.map((order) => (
                <tr key={`${order.id}`}>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="flex items-center gap-3">
                      <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
                        {order.order_num}
                      </p>
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                      {order.table_num}
                    </p>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                      {order.date_order}
                    </p>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="w-max">
                      <div
                        className="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-green-500/20 text-green-900 py-1 px-2 text-xs rounded-md"
                        style={getStatusStyles(order.status)}
                      >
                        <span className="">{order.status}</span>
                      </div>
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                      <MdAccessTime className="me-1.5" size={20} />
                      {calculateTimeDifference(order.date_order)}
                    </span>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="flex items-center gap-3">
                      {order.plat_name}
                      <span className="inline-flex items-center justify-center w-6 h-6 ms-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                        x {order.qty}
                      </span>
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="flex items-center gap-3">
                      <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                        {order.department}
                      </p>
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="flex items-center gap-3">
                      <Button
                        variant="contained"
                        endIcon={<MdCreditScore />}
                        onClick={() => openModal(order)}
                      >
                        Changer Table
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => showInvoice(order)}
                        endIcon={<AiFillCarryOut />}
                      >
                        Ticket
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Fragment>
        {invoiceShow && (
          <Invoice
            closeInvoice={closeInvoice}
            rowOrders={rowOrders}
            filteredOrders={filteredOrders}
            setFilteredOrders={setFilteredOrders}
            invoiceShow={invoiceShow}
          />
        )}
      </Fragment>
    </Fragment>
  );
}

export default Controlle;
