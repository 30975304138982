import React, { useEffect, useState } from "react";
import { config_url } from "../../config";
import axios from "axios";

const StatisticsOrders = () => {
  const [statistics, setStatistics] = useState([]);
  console.log(statistics);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(
          `${config_url}/api/orders/statistics-problems/yyy`
        ); // Adjust the URL as needed
        setStatistics(response.data);
      } catch (err) {
        console.error("Error fetching statistics:", err);
        setError("Failed to fetch statistics");
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="overflow-x-auto">
      <h1 className="text-2xl font-bold mb-4">Order Details</h1>

      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">Order Number</th>
            <th className="py-3 px-6 text-left">Departments</th>
            <th className="py-3 px-6 text-left">Plat Names</th>
            <th className="py-3 px-6 text-left">Retard moyen</th>
            <th className="py-3 px-6 text-left">Problems</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {statistics.map((order) => (
            <tr
              key={order.order_num}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              <td className="py-3 px-6">{order.order_num}</td>
              <td className="py-3 px-6">
                {order.departments.length > 0
                  ? order.departments.join(", ")
                  : "N/A"}
              </td>
              <td className="py-3 px-6">{order.plat_names.join(", ")}</td>
              <td className="py-3 px-6">
                {order.avg_time_retard
                  ? `${order.avg_time_retard} minutes`
                  : "N/A"}
              </td>
              <td className="py-3 px-6">
                {order.problems.length > 0 ? order.problems.join(", ") : "None"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StatisticsOrders;
