import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import PublicRoutesLayout from "./layouts/PublicRoutesLayout";
//import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Page404 from "./pages/page404";
import AddUser from "./pages/auth/AddUser";
import ListUsers from "./pages/auth/ListUsers";
import AddProduit from "./pages/produit/AddProduit";
import ListProduits from "./pages/produit/ListProduits";
import Test from "./pages/Test";
import UpdProduit from "./pages/produit/UpdProduit";
import PriorityProd from "./pages/PriorityProduct/PriorityProd";
import UpdUser from "./pages/auth/UpdUser";
import HidenProduits from "./pages/produit/HidenProduits";
import DetailsProd from "./pages/produit/DetailsProd";
import LoginRouteLayout from "./layouts/LoginRouteLayout";
import Langues from "./pages/Langues/Langues";
import Category from "./pages/Menu/Category";
import ProductDetail from "./pages/Menu/ProductDetails";
import ProtectedRoute from "./ProtectedRoute";
import ProductRelations from "./pages/produit/ProductRelations";
import AddRelationWithProd from "./pages/produit/AddRelationWithProd";
import UpdProdRelations from "./pages/produit/UpdProdRelations";
import DepartmentDashboard from "./components/DepartmentDashboard";
import Dressing from "./pages/dressing/Dressing";
import Controlle from "./pages/controlle/Controlle";
import AddPlat from "./pages/plats/AddPlat";
import ListPlats from "./pages/plats/ListPlats";
import Tables from "./pages/table/Tables";
import AddCategory from "./pages/category/AddCategory";
import ListCategories from "./pages/category/ListCategories";
import ListProdsDefaut from "./pages/produitsDefaut/ListProdsDefaut";
import ListOrders from "./pages/orders/ListOrders";
import Fornisseur from "./pages/fornisseur/Fornisseur";
import OrderDetails from "./pages/statistics/OrderDetails";
import StatisticsOrders from "./pages/statistics/StatisticsOrders";
import SalesPage from "./pages/sales/SalesPage";
import Friteuse from "./pages/friteuse/Friteuse";

export default function Router() {
  return useRoutes([
    {
      path: "/app",
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: "utilisateurs", element: <ListUsers /> },
        { path: "changer-user/:id", element: <UpdUser /> },
        { path: "ajoute-utilisateur", element: <AddUser /> },
        { path: "ajoute-produit", element: <AddProduit /> },
        { path: "list-produits", element: <ListProduits /> },
        { path: "controlle-produits-fornisseur", element: <Fornisseur /> },
        { path: "list-plats", element: <ListPlats /> },
        { path: "statistics", element: <StatisticsOrders /> },
        { path: "statistics1", element: <OrderDetails /> },
        { path: "statistics/sales", element: <SalesPage /> },
        { path: "ajoute-plat", element: <AddPlat /> },

        { path: "ajoute-produit-relation", element: <AddRelationWithProd /> },
        {
          path: "changer-produit-relation/:id",
          element: <UpdProdRelations />,
        },

        { path: "product-relations", element: <ProductRelations /> },
        { path: "hiden-produits", element: <HidenProduits /> },
        { path: "changer-produit/:id", element: <UpdProduit /> },
        { path: "details-produit/:id", element: <DetailsProd /> },
        { path: "order-produits", element: <PriorityProd /> },
        { path: "/app/produit-defauts", element: <ListProdsDefaut /> },
        { path: "ajoute-categorie", element: <AddCategory /> },
        { path: "list-categories", element: <ListCategories /> },
        { path: "list-orders", element: <ListOrders /> },

        { path: "test", element: <Test /> },
      ],
    },
    {
      path: "/login",
      element: <LoginRouteLayout />,
      children: [
        { index: true, element: <Login /> },
        { path: "page404", element: <Page404 /> },
      ],
    },
    {
      path: "/",
      element: <PublicRoutesLayout />,
      children: [
        { index: true, element: <Login /> },
        { path: "tables", element: <Tables /> },
        {
          path: "department/friteuse",
          element: <DepartmentDashboard department="friteuse" />,
        },
        {
          path: "department/plancha",
          element: <DepartmentDashboard department="plancha" />,
        },
        {
          path: "department/saladre",
          element: <DepartmentDashboard department="saladre" />,
        },
        {
          path: "dressing",
          element: <Dressing />,
        },
        {
          path: "friteuse",
          element: <Friteuse />,
        },
        {
          path: "controlle",
          element: <Controlle />,
        },
        { path: "Pos/menu/category", element: <Category /> },
        { path: "Pos/menu/category/details", element: <ProductDetail /> },

        { path: "page404", element: <Page404 /> },
      ],
    },
    { path: "*", element: <Navigate to="/page404" replace={true} /> },
  ]);
}
