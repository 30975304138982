import { motion } from "framer-motion";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";
import { config_url } from "../../config";

// Function to aggregate monthly sales
const aggregateMonthlySales = (orders) => {
  console.log(orders);
  const salesData = {};

  orders.forEach((order) => {
    const date = new Date(order.date);
    const month = date.toLocaleString("default", { month: "short" });

    if (!salesData[month]) {
      salesData[month] = 0;
    }

    salesData[month] += order.total_price_sum;
  });

  return Object.keys(salesData).map((month) => ({
    month,
    sales: salesData[month],
  }));
};

// Function to aggregate last week's sales
const aggregateLastWeekSales = (orders) => {
  console.log(orders);
  const salesData = {};
  const now = new Date();
  const lastWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 7
  );

  orders.forEach((order) => {
    const date = new Date(order.date);

    if (date >= lastWeek && date <= now) {
      const day = date.toLocaleDateString("default", { weekday: "short" });

      if (!salesData[day]) {
        salesData[day] = 0;
      }

      salesData[day] += order.total_price_sum;
    }
  });

  return Object.keys(salesData).map((day) => ({
    day,
    sales: salesData[day],
  }));
};

const SalesOverviewChart = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState("This Month");
  const [salesData, setSalesData] = useState([]);

  useEffect(() => {
    // Fetch orders from the API
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${config_url}/api/orders-daily`);
        const data = await response.json();

        if (selectedTimeRange === "This Month") {
          setSalesData(aggregateMonthlySales(data));
        } else if (selectedTimeRange === "Last Week") {
          setSalesData(aggregateLastWeekSales(data));
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [selectedTimeRange]);

  return (
    <motion.div
      className="bg-gray-100 bg-opacity-50 backdrop-blur-md shadow-lg rounded-xl p-6 border border-gray-700 mb-8"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
    >
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-black-100">Sales Overview</h2>

        <select
          className="bg-gray-700 text-white rounded-md px-3 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={selectedTimeRange}
          onChange={(e) => setSelectedTimeRange(e.target.value)}
        >
          <option>Last Week</option>
          <option>This Month</option>
          <option>This Quarter</option>
          <option>This Year</option>
        </select>
      </div>

      <div className="w-full h-80">
        <ResponsiveContainer>
          <AreaChart data={salesData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis
              dataKey={selectedTimeRange === "Last Week" ? "day" : "month"}
              stroke="#9CA3AF"
            />
            <YAxis stroke="#9CA3AF" />
            <Tooltip
              contentStyle={{
                backgroundColor: "rgba(31, 41, 55, 0.8)",
                borderColor: "#4B5563",
              }}
              itemStyle={{ color: "#E5E7EB" }}
            />
            <Area
              type="monotone"
              dataKey="sales"
              stroke="#8B5CF6"
              fill="#8B5CF6"
              fillOpacity={0.3}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </motion.div>
  );
};

export default SalesOverviewChart;
