import React, { useEffect, useState, Fragment } from "react";
import io from "socket.io-client";
import { config_url } from "../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import { getOrdersByStatus } from "../../services/api";
import { getOrders } from "../../services/api";
import { getOrdersByDepartment } from "../../services/api";

import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import {
  MdAutorenew,
  MdBackHand,
  MdSoupKitchen,
  MdArrowBack,
} from "react-icons/md";

const socket = io(`${config_url}`);

const Dressing = () => {
  const { User } = useSelector((state) => state.userInfo);
  const [orders, setOrders] = useState([]);
  const [displayButton, setDisplayButton] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date()); // State for current time

  const [filteredOrders, setFilteredOrders] = useState([]);

  console.log(orders);

  useEffect(() => {
    socket.emit("join-department", "dressing");

    socket.on("new-order", (order) => {
      setOrders((prevOrders) => [...prevOrders, order]);
    });
    socket.on("all-orders-updated", (allOrders) => {
      setOrders(allOrders);
    });

    const fetchOrders = async () => {
      try {
        const response = await getOrders();
        await setOrders(response.data.orders);
        await setFilteredOrders(response.data.orders);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrders();

    return () => {
      socket.off("new-order");
      socket.off("all-orders-updated");
    };
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getOrders();
        const allOrders = response.data.orders;
        const dressingOrders = allOrders.filter(
          (order) => order.status === "dressing"
        );
        setOrders(allOrders);
        setFilteredOrders(dressingOrders);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrders();
  }, [orders]);
  const updateOrderStatus = async (orderId, status) => {
    try {
      // Make a PATCH request to update the order status
      const response = await axios.patch(
        `${config_url}/api/orders/${orderId}`,
        {
          status: status, // New status
        }
      );

      console.log(response.data.message); // Log success message
    } catch (error) {
      console.error(
        "Error updating order status:",
        error.response.data.message
      );
    }
  };
  const calculateTimeDifference = (orderTime) => {
    const orderDate = new Date(orderTime);
    const timeDiff = Math.abs(currentTime - orderDate);
    const minutes = Math.floor(timeDiff / 1000 / 60); // Only calculate minutes
    return `${minutes} minutes ago`;
  };
  const ButtonComponent = ({ displayButton, fetchOldOrders, backToMoment }) => {
    console.log("ButtonComponent render", displayButton);
    return displayButton ? (
      <Button
        variant="outlined"
        endIcon={<MdAutorenew />}
        onClick={() => fetchOldOrders()}
      >
        Load History
      </Button>
    ) : (
      <Button
        variant="outlined"
        endIcon={<MdArrowBack />}
        onClick={() => backToMoment()}
      >
        Back History
      </Button>
    );
  };

  return (
    <Fragment>
      <div className="flex min-h-screen items-center bg-white">
        <div className="p-6 overflow-scroll px-0">
          {/* <div className="flex m-10">
            <ButtonComponent
              displayButton={displayButton}
              fetchOldOrders={fetchOldOrders}
              backToMoment={backToMoment}
            />
          </div> */}

          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Order Num
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Table
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Order Time
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Status
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Order Details
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Qty
                  </p>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Action
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders?.map((order, orderIndex) => (
                <tr key={`${order.id}`}>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="flex items-center gap-3">
                      <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
                        {order.order_num}
                      </p>
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                      {order.table_num}
                    </p>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                      {calculateTimeDifference(order.date_order)}
                    </p>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="w-max">
                      <div
                        className="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-green-500/20 text-green-900 py-1 px-2 text-xs rounded-md"
                        style={{ opacity: 1 }}
                      >
                        <span className="">{order.status}</span>
                      </div>
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="flex items-center gap-3">
                      {order.plat_name}
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="flex items-center gap-3">{order.qty}</div>
                  </td>
                  <td className="flex items-center justify-center gap-4 p-4 border-b border-blue-gray-50">
                    <Button
                      variant="outlined"
                      endIcon={<SendIcon />}
                      onClick={() => updateOrderStatus(order.id, "ready")}
                    >
                      Ready To Controle
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default Dressing;
