import React, { Fragment, useState, useEffect } from "react";
import {
  MdCloudUpload,
  MdDelete,
  MdCreate,
  MdAdd,
  MdAddCircle,
} from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import Alert from "@mui/material/Alert";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SelectOpt from "react-select";

import axios from "axios";
import { config_url } from "../../config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll", // This will add a vertical scrollbar
};

const options = [
  { value: "foodWaste", label: "Food Waste" },
  { value: "theft", label: "سرقة" },
  { value: "poorInventoryManagement", label: "سوء إدارة المخزون" },
  { value: "spoilage", label: "إفساد" },
  { value: "inefficientOrdering", label: "الطلب غير الفعّال" },
  { value: "menuDesign", label: "Menu Design" },
  { value: "other", label: "Other" },
];

function VerifyStock({ closeModal, rowProduit, setShowTableProdNotValid }) {
  const [quantity, setQuantity] = useState(0);
  const [justification, setJustification] = useState("");
  const [problems, setProblems] = useState([]);
  console.log(rowProduit.id);
  const [showJustification, setShowJustification] = useState(false);

  const [showSpinner, setShowSpinner] = useState(true);
  const [showSelectCategories, setShowSelectCategories] = useState(false);
  const [showButtonCloture, setShowButtonCloture] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSubmitCheck = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config_url}/api/ajoute-quantite-manquer/${rowProduit.id}`,
        {
          quantity,
        }
      );
      if (response.data.valid) {
        toast.success("Quantity is valid!");
        setShowSelectCategories(false);
        setShowButtonCloture(true);
      } else {
        toast.error("Quantity is not valid!");
        setShowSelectCategories(true);
      }
    } catch (error) {
      toast.error("An error occurred!");
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config_url}/api/add-problems`, {
        product_id: rowProduit.id,
        problems,
        justification: justification,
        quantity,
      });
      if (response.data.success) {
        toast.success("Problems added successfully!");
        setShowButtonCloture(true);
      } else {
        toast.error("Failed to add problems!");
      }
    } catch (error) {
      toast.error("An error occurred!");
    }
  };

  const handleChange = (selected) => {
    const updatedOptions = selected.map((option) => ({
      ...option,
      number_item: "",
    }));
    setSelectedOptions(updatedOptions);
    setProblems(updatedOptions);

    // Check if "Other" is selected
    if (selected.some((option) => option.label === "Other")) {
      setShowJustification(true);
    } else {
      setShowJustification(false);
    }
  };

  const handleInputChange = (index, value) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index].number_item = value;
    setSelectedOptions(updatedOptions);
    setProblems(updatedOptions);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 5000); // Change the delay time as needed (3000ms = 3 seconds)

    return () => clearTimeout(timer);
  }, []);

  const handleCloture = async () => {
    try {
      const response = await axios.post(`${config_url}/api/cloture`);
      console.log("Response:", response.data);

      setTimeout(() => {
        window.location.reload();
      }, 100);
      await closeModal();
    } catch (error) {
      console.error("There was an error updating the stock!", error);
    }
  };

  return (
    <Fragment>
      <Modal
        open={true} // Modal is controlled by parent component
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-full bg-gray-100 p-2 mb-4">
            <Typography variant="h5" gutterBottom>
              Verifiez Quantity {rowProduit.name}
            </Typography>
          </div>
          <Divider />
          <div className="mt-4 flex items-center justify-center justify-between">
            <form
              onSubmit={handleSubmitCheck}
              className="flex items-center gap-10 mt-4 mb-4 w-full"
            >
              <Alert severity="warning">
                {" "}
                Allez maintenant Pour Calculer la qte de: {rowProduit.name}
              </Alert>
              <TextField
                id="outlined-basic"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                label="Quantité Trouvée"
                type="number"
                defaultValue={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                placeholder="Enter quantity"
                inputProps={{
                  step: 0.01,
                }}
              />
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                startIcon={<MdAddCircle />}
              >
                Verifiez
              </Button>{" "}
            </form>
          </div>
          {showJustification && (
            <div className="mt-4 mb-4">
              <TextField
                id="outlined-multiline-static"
                label="Justification For Other "
                multiline
                defaultValue={justification}
                onChange={(e) => setJustification(e.target.value)}
                rows={4}
              />
            </div>
          )}
          {showSelectCategories && (
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                {showSpinner ? (
                  <ClipLoader loading={showSpinner} size={20} />
                ) : (
                  <div className="mt-10 flex flex-col items-center justify-center gap-4">
                    <Typography variant="h5" gutterBottom>
                      Selectez Problem de Perte
                    </Typography>
                    <div className="w-[100%]">
                      <SelectOpt
                        isMulti
                        name="problems"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleChange}
                        value={selectedOptions}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div>
                {selectedOptions.map((option, index) => (
                  <div
                    className="flex items-center justify-center gap-4 mt-10"
                    key={option.value}
                  >
                    <label>{option.label}</label>
                    <input
                      className="w-50 appearance-none block bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-sky-500"
                      type="number"
                      value={option.number_item}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      placeholder="Enter number of items"
                    />
                  </div>
                ))}
              </div>
              <button className="mt-10" onClick={(e) => handleSend(e)}>
                Valider
              </button>
            </div>
          )}
          {showButtonCloture && (
            <div className="mt-10 flex flex-col items-center justify-center gap-6">
              <Alert severity="success">
                {" "}
                Cliquez sur button Cloture pour retour Tous valeurs au stock
                initiale.
              </Alert>
              <button
                className="bmt-10 g-blue-500 hover:bg-blue-400 text-black font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"
                onClick={handleCloture}
              >
                Cloture
              </button>
            </div>
          )}
        </Box>
      </Modal>
    </Fragment>
  );
}

export default VerifyStock;
