import React, { Fragment, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { detailsProduct } from "../../slices/detailsProduct";

import { toast } from "react-toastify";
import { config_url } from "../../config";
import SelectOpt from "react-select";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import {
  RiAddCircleFill,
  RiDeleteBin6Fill,
  RiEdit2Line,
  RiEyeOffLine,
  RiEyeLine,
  RiCheckDoubleFill,
  RiCloseLargeFill,
} from "react-icons/ri";
import { Tooltip, Typography } from "@mui/material";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

function ProductRelations() {
  const [listProds, setListProds] = useState([]);
  console.log(listProds);
  const [products_related, setProductsRelated] = useState([]);
  console.log(products_related);
  const [listFiltred, setListFiltred] = useState([]);
  const [disable_button, setDisable] = useState(true);
  const [category, setSelectCategory] = useState("Tous");
  const [selectVille, setSelectVille] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  console.log(selectedCategories);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  console.log(rowSelectionModel);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (chipToDelete, product_id) => async () => {
    await setProductsRelated((chips) =>
      chips.filter((chip) => chip.related_id !== chipToDelete.related_id)
    );
    await popup(product_id, chipToDelete.related_id);
    setTimeout(() => {
      window.location.reload(false);
    }, "5000");
  };

  useEffect(() => {
    axios.get(`${config_url}/api/related-products`).then(async (res) => {
      await setListProds(res.data);
      await setListFiltred(res.data);
    });
  }, []);

  useEffect(() => {
    setDisable(rowSelectionModel.length === 0);
  }, [rowSelectionModel]);

  function popup(product_id, related_id) {
    Swal.fire({
      title: "Êtes vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, supprimez Le Relation !!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(
          `${config_url}/api/product-relations/${product_id}/${related_id}`
        );
        Swal.fire("Supprimé!", "Relation a été supprimé.", "success");
      }
    });
  }

  //Filters
  const filterCategory = (e) => {
    if (listProds.length > 0) {
      setSelectCategory(e.target.value);
      if (listProds.length > 0) {
        const newFilter = listProds.filter(
          (bon) => bon.category === e.target.value
        );
        setListFiltred(newFilter);
        if (e.target.value === "Tous") {
          setListFiltred(listProds);
          return;
        }
      }
    }
  };

  //Filters

  const selOptions = [];
  const ids = listFiltred?.map((o) => o.product_name);
  const filtered = listFiltred?.filter(
    ({ product_name }, index) => !ids?.includes(product_name, index + 1)
  );

  for (let i = 0; i < filtered.length; i++) {
    if (filtered.length > 0) {
      selOptions.push({
        value: filtered[i].product_name,
        label: filtered[i].product_name,
        id: filtered[i].id,
      });
    }
  }
  const handle = (e) => {
    console.log(e);
    const value = e.map((option) => option.label);
    setSelectVille(value);
    setSelectedCategories(e);
  };
  const handleRemoveOption = (removedValue) => {};

  const customMultiValue = (props) => (
    <div className="flex items-center justify-center gap-2 ml-2 font-bold bg-gray-300 rounded-2xl p-1">
      <div>{props.data.label}</div>
      <button
        onClick={(e) => {
          props.removeProps.onClick();
          handleRemoveOption(props.data.label);
        }}
        className="w-8 h-8 rounded-full text-black p-1 bg-red-400"
      >
        X
      </button>
    </div>
  );

  const ajouteProductCategory = () => {
    axios
      .post(`${config_url}/api/add-related-products`, {
        related_ids: selectedCategories,
        product_ids: rowSelectionModel,
      })
      .then(() => {
        toast.success("Ajoute Produit Au Groupe !!", {
          position: "top-right",
        });
      });
    setTimeout(() => {
      window.location.reload(false);
    }, "2000");
  };

  function AddToProductWithRelations() {
    Swal.fire({
      title: "Êtes vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#D2691E",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Yes, Create Relation Between Product(s)",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        Swal.fire("Created Relation Between Product(s)", {
          icon: "success",
        });
        ajouteProductCategory();
      }
    });
  }

  const handleProductRelations = (id) => {
    handleOpen();
    axios.get(`${config_url}/api/products-related/${id}`).then((res) => {
      setProductsRelated(res.data);
    });
  };

  const detailsProd = (dts) => {
    dispatch(detailsProduct(dts));
    navigate("/app/changer-produit-relation/" + dts.id);
  };

  const columns = [
    {
      field: "product_name",
      headerName: "Product:",
      headerClassName: "super-app-theme--cell",
      width: 200,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-line", overflow: "hidden" }}>
          <Typography>{params.row.product_name}</Typography>
        </div>
      ),
    },
    {
      field: "category",
      headerName: "Category:",
      headerClassName: "super-app-theme--cell",
      width: 140,
    },
    {
      field: "prix",
      headerName: "Price:",
      headerClassName: "super-app-theme--cell",
      width: 100,
    },
    {
      field: "image",
      headerName: "Image:",
      headerClassName: "super-app-theme--cell",
      width: 100,
      renderCell: (params) =>
        params.row.image ? (
          <div
            style={{
              backgroundImage: `url(${config_url}/images/${params.row.image})`,
              width: "50px",
              height: "50px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        ) : (
          <Typography>No Image: </Typography>
        ),
    },

    {
      field: "modification",
      headerName: "Modifications",
      width: 220,
      renderCell: (params) => {
        return (
          <div className="mt-2 flex gap-4">
            <div>
              <Tooltip title="Informations Sur Produirt" placement="top">
                <div>
                  <RiEdit2Line
                    className="collabListEdit"
                    onClick={() => detailsProd(params.row)}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Fragment>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {products_related.map((data) => {
                let icon;

                return (
                  <ListItem key={data.related_id}>
                    <Chip icon={icon} label={data.related_product_name} />
                  </ListItem>
                );
              })}
            </Paper>
          </Box>
        </Modal>
      </Fragment>
      <div className="page__main">
        <Link to="/app/ajoute-produit-relation">
          <Button variant="outlined" startIcon={<RiAddCircleFill />}>
            Add Relation Product
          </Button>
        </Link>
        <div className="w-[180px] bg-white p-4 mt-6 rounded-xl flex items-center justify-between">
          <div>
            <label className="ml-5 mb-2 block text-lg font-bold text-black">
              Category:
            </label>

            <select
              className="ml-5 mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-50 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={category}
              onChange={(e) => filterCategory(e)}
            >
              <option value="Tous">All</option>
              <option value="plat-grille">Plats-Grilles</option>
              <option value="plat-saute">Plats-Sautés</option>
              <option value="plat-frit">Plats-Frits</option>
              <option value="boisson-jus">Boisson Et Jus</option>
              <option value="flan-dissert">Flan Et Disserts</option>
              <option value="entree-salade">Entrée Et Salades</option>
            </select>
          </div>
        </div>

        {!disable_button && (
          <div className="flex items-center justify-center bg-gray-200 rounded-3xl gap-4">
            <div className="flex gap-4 mb-8">
              <div className="flex flex-col items-center">
                <span className="text-black font-bold">Commanded With :</span>
                <SelectOpt
                  className="selOptions"
                  options={selOptions}
                  isMulti
                  onChange={handle}
                  components={{ MultiValue: customMultiValue }}
                />
              </div>
            </div>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold rounded-2xl"
              onClick={() => AddToProductWithRelations()}
            >
              Valider
            </button>
          </div>
        )}
        <h1>List Products:</h1>

        <Box
          sx={{
            height: "auto",
            width: "auto",
            "& .super-app-theme--cell": {
              backgroundColor: "#fff",
              color: "#1a3e72",
              fontWeight: "bold",
            },
            boxShadow: 2,
            border: 2,
            borderRadius: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
        >
          <DataGrid
            rows={listFiltred}
            columns={columns}
            getRowId={(row) => row.id}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
              if (newRowSelectionModel.length === 0) {
                setDisable(true);
              }
              setDisable(false);
            }}
            rowSelectionModel={rowSelectionModel}
          />
        </Box>
      </div>
    </Fragment>
  );
}

export default ProductRelations;
