import { config_url } from "../config";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import "./Task.css";

export const Task = ({ id, title, image, category, priority }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="task"
    >
      {" "}
      <span className="inline-flex items-center ml-12 px-3 py-1 bg-red-200  rounded-full text-sm font-semibold text-black">
        {priority}
      </span>
      <div>{title}</div>
      <span className="inline-flex items-center ml-12 px-3 py-1 bg-green-200 hover:bg-green-300 rounded-full text-sm font-semibold text-green-600">
        {category}
      </span>
      <div>
        <img
          src={`${config_url}//images/${image}`}
          alt={title}
          className="h-20 w-20 rounded-full"
        />
      </div>
    </div>
  );
};
