import { Fragment, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { config_url } from "../../config";
import VerifyStock from "./VerifyStock";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { RiQuestionFill } from "react-icons/ri";
import { Typography } from "@mui/material";

function ProdsNotValid({ listProdsNotValid }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowProduit, setRowProduit] = useState({});

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal = (row) => {
    setIsModalOpen(true);
    setRowProduit(row);
  };

  const columns = [
    {
      field: "name",
      headerName: "Product:",
      headerClassName: "super-app-theme--cell",

      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-line", overflow: "hidden" }}>
            <Typography>{params.row.name}</Typography>
          </div>
        );
      },
    },
    {
      field: "stock_initiale",
      headerName: "Qte Initial:",
      headerClassName: "super-app-theme--cell",

      width: 140,
    },
    {
      field: "stock_restant",
      headerName: "Qte Rester:",
      headerClassName: "super-app-theme--cell",

      width: 140,
    },
    {
      field: "stock_vendue",
      headerName: "Qte Vendue:",
      headerClassName: "super-app-theme--cell",
      width: 140,
      renderCell: (params) => {
        return (
          <div className="mt-4">
            <Typography>
              {params.row.stock_vendue}
              {params.row.unity}
            </Typography>
          </div>
        );
      },
    },

    {
      field: "prix",
      headerName: "Price:",
      headerClassName: "super-app-theme--cell",

      width: 100,
    },
    {
      field: "unity",
      headerName: "Unite",
      headerClassName: "super-app-theme--cell",

      width: 120,
    },
    {
      field: "date_dernier_stockage",
      headerName: "Date Stockage",
      headerClassName: "super-app-theme--cell",

      width: 120,
    },

    {
      field: "image",
      headerName: "Image :",
      headerClassName: "super-app-theme--cell",

      width: 100,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundImage: `url(${config_url}/produits/${params.row.image})`,
              width: "50px",
              height: "50px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        );
      },
    },
    {
      field: "modification",
      headerName: "Modifications",
      width: 340,
      renderCell: (params) => {
        return (
          <div className="mt-2 flex items-start">
            <Button
              variant="outlined"
              startIcon={<RiQuestionFill />}
              onClick={() => openModal(params.row)}
            >
              Justifier
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <Fragment>
      {isModalOpen && (
        <VerifyStock closeModal={closeModal} rowProduit={rowProduit} />
      )}
      <Box
        sx={{
          height: "auto",
          width: "75vw",
          "& .super-app-theme--cell": {
            backgroundColor: "#fff",
            color: "#1a3e72",
            fontWeight: "bold",
          },
          boxShadow: 2,
          border: 2,
          borderRadius: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      >
        <DataGrid
          rows={listProdsNotValid}
          columns={columns}
          getRowId={(row) => row.id}
        />
      </Box>
    </Fragment>
  );
}

export default ProdsNotValid;
