import { motion } from "framer-motion";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { config_url } from "../../config";

// Helper function to process orders data (formatting the date if necessary)
const processOrdersData = (orders) => {
  return orders.map((order) => ({
    ...order,
    date: moment(order.date).format("YYYY-MM-DD"), // Format the date if needed
  }));
};

const DailyOrders = () => {
  const [dailyOrdersData, setDailyOrdersData] = useState([]);

  useEffect(() => {
    axios
      .get(`${config_url}/api/orders-daily`)
      .then((response) => {
        const processedData = processOrdersData(response.data);
        setDailyOrdersData(processedData);
      })
      .catch((error) => {
        console.error("Error fetching orders data:", error);
      });
  }, [config_url]); // config_url should be part of the dependency array

  return (
    <motion.div
      className="bg-gray-100 bg-opacity-50 backdrop-blur-md shadow-lg rounded-xl p-6 border border-gray-700"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
    >
      <h2 className="text-xl font-semibold text-black-100 mb-4">
        Daily Orders
      </h2>

      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <LineChart data={dailyOrdersData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis dataKey="date" stroke="#9CA3AF" />
            <YAxis stroke="#9CA3AF" />
            <Tooltip
              contentStyle={{
                backgroundColor: "rgba(31, 41, 55, 0.8)",
                borderColor: "#4B5563",
              }}
              itemStyle={{ color: "#E5E7EB" }}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="orders"
              stroke="#8B5CF6"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="total_price_sum"
              stroke="#34D399"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </motion.div>
  );
};

export default DailyOrders;
