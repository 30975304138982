import React, { Fragment, useState, useEffect } from "react";
import { MdCloudUpload, MdDelete, MdCreate, MdAdd } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SelectOpt from "react-select";

import axios from "axios";
import { config_url } from "../../config";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll", // This will add a vertical scrollbar
};

function UpdPlat({ closeModal, rowPlat }) {
  const [plat_name, setPlatName] = useState(rowPlat.plat_name);
  const [plat_prix, setPlatPrix] = useState(rowPlat.plat_prix);
  const [plat_prix_promo, setPlatPrixPromo] = useState(rowPlat.plat_prix_promo);
  const [category, setSelectCategory] = useState(rowPlat.plat_category);
  const [departement, setDepartement] = useState(rowPlat.plat_departement);
  const [out_of_stock, setOut] = useState(rowPlat.out_stock);

  const [listProds, setListProds] = useState([]);

  const [products, setProducts] = useState(rowPlat.products || []);

  const [productList, setProductList] = useState([]);
  console.log(productList);
  const [affectedCategories, setAffectedCategories] = useState([]);
  const [changer_categories, setChangerCategories] = useState(false);
  const [showSelectCategories, setShowSelectCategories] = useState(false);

  const [affectedCategoriesIds, setAffectedCategoriesIds] = useState([]);

  const [image, setImage] = useState(
    `${config_url}/plats/${rowPlat.plat_image}`
  );
  const [fileName, setFileName] = useState("No selected file");
  const [oldImage, setOldImage] = useState(rowPlat.image);
  const [upload_image, setUploadedImage] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    if (affectedCategories) {
      const updatedProductList = affectedCategories?.map((option) => option.id);
      setAffectedCategoriesIds(updatedProductList);
    }
  }, [affectedCategories]);
  useEffect(() => {
    axios
      .get(`${config_url}/api/products`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(async (res) => {
        await setListProds(res.data);
      });
  }, []);
  useEffect(() => {
    if (products) {
      const updatedProductList = products?.map((data) => ({
        productId: data.product_id,
        productName: data.product_contain_name,
        quantity: data.quantity,
      }));
      setProductList(updatedProductList);
    }
  }, [products]);

  const handleChange = async () => {
    const formdata = new FormData();
    formdata.append("image_plat", image);
    formdata.append("oldimage", oldImage);
    formdata.append("upload_image", upload_image);
    formdata.append("plat_name", plat_name);
    formdata.append("plat_prix", plat_prix);
    formdata.append("plat_prix_promo", plat_prix_promo);
    formdata.append("out_of_stock", out_of_stock);

    formdata.append("category", category);
    formdata.append("departement", departement);
    formdata.append("changed_category", changer_categories);

    formdata.append("affected_categories", productList);
    try {
      const response = await axios.put(
        `${config_url}/api/update-plat/${rowPlat.plat_id}`,

        formdata,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200)
        toast.success("Changement Plat Success !!", {
          position: "top-right",
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleImage = (file) => {
    setUploadedImage(true);
    setImage(file);
  };
  const handleSelectDepartement = (e) => {
    setDepartement(e.target.value);
  };

  const handleSelectCategory = (e) => {
    setSelectCategory(e.target.value);
  };
  const handleSelectOutOfStock = (e) => {
    setOut(e.target.value);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 3000); // Change the delay time as needed (3000ms = 3 seconds)

    return () => clearTimeout(timer);
  }, []);
  const selOptions = [];
  const ids = listProds?.map((o) => o.name);
  const filtered = listProds?.filter(
    ({ name }, index) => !ids?.includes(name, index + 1)
  );

  for (let i = 0; i < filtered.length; i++) {
    if (filtered.length > 0) {
      selOptions.push({
        value: filtered[i].name,
        label: filtered[i].name,
        id: filtered[i].id,
      });
    }
  }

  const handle = (selectedOptions) => {
    const selectedProducts = selectedOptions.map((option) => ({
      productId: option.id,
      productName: option.label,
    }));

    setChangerCategories(true);
    setAffectedCategories(selectedOptions);

    // Update the product list with selected products
    setProductList((prevList) => [
      ...prevList,
      ...selectedProducts.filter(
        (selected) =>
          !prevList.some((item) => item.productId === selected.productId)
      ),
    ]);
  };

  return (
    <Fragment>
      <Modal
        open={true} // Modal is controlled by parent component
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-full bg-gray-100 p-2 mb-4">
            <Typography variant="h5" gutterBottom>
              Edit Plat {rowPlat.plat_name}
            </Typography>
          </div>
          <Divider />
          <div className="mt-4 flex items-center justify-center justify-between">
            <TextField
              id="outlined-basic"
              label="Name Plat"
              variant="outlined"
              defaultValue={plat_name}
              onChange={(e) => setPlatName(e.target.value)}
            />
            <div className="mt-4 mb-4">
              <TextField
                id="outlined-basic"
                label="Price"
                variant="outlined"
                defaultValue={plat_prix}
                onChange={(e) => setPlatPrix(e.target.value)}
              />
            </div>
            <div className="mt-4 mb-4">
              <TextField
                id="outlined-basic"
                label="Price Promo"
                variant="outlined"
                defaultValue={plat_prix_promo}
                onChange={(e) => setPlatPrixPromo(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-[150px]">
            <div
              className="form mb-[150px]"
              onClick={() => document.querySelector(".input-field").click()}
            >
              <input
                type="file"
                className="input-field"
                hidden
                onChange={({ target: { files } }) => {
                  files[0] && setFileName(files[0].name);
                  if (files) {
                    handleImage(files[0]);
                  }
                }}
              />

              {image ? (
                <img
                  className="rounded-full"
                  src={
                    typeof image === "string"
                      ? image
                      : URL.createObjectURL(image)
                  }
                  width={150}
                  height={150}
                  alt={fileName}
                />
              ) : (
                <>
                  <MdCloudUpload color="#1475cf" size={60} />
                  <p>Browse Files to upload</p>
                </>
              )}
            </div>
            <section className="w-90 uploaded-row">
              <AiFillFileImage color="#1475cf" />
              <span className="upload-content">
                {fileName} -
                <MdDelete
                  onClick={() => {
                    setFileName("No selected File");
                    setImage(null);
                  }}
                />
              </span>
            </section>
            <div className="mt-4 flex items-center justify-center justify-between">
              {rowPlat.plat_departement !== "pret" && (
                <div className="flex flex-wrap">
                  <div className="mt-10 flex flex-col gap-4">
                    <Button
                      onClick={() => setShowSelectCategories(true)}
                      variant="contained"
                      endIcon={<MdAdd />}
                    >
                      Affect Product(s) To Plat
                    </Button>
                    {showSelectCategories && (
                      <div>
                        {showSpinner ? (
                          <ClipLoader loading={showSpinner} size={10} />
                        ) : (
                          <div className="flex flex-col items-center">
                            <span className="text-black font-bold">
                              Categorie :
                            </span>
                            <SelectOpt
                              className="Options"
                              options={selOptions}
                              isMulti
                              onChange={handle}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="w-90 px-3 mb-6 md:mb-0">
                <Box sx={{ ml: 2, minWidth: 220 }}>
                  <FormControl sx={{ minWidth: 220 }}>
                    <label className="mt-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Departement:
                    </label>{" "}
                    <Select
                      value={departement}
                      onChange={handleSelectDepartement}
                      sx={{ backgroundColor: "#fff" }}
                    >
                      <MenuItem value="plancha">Plancha</MenuItem>
                      <MenuItem value="friteuse">Friteuse</MenuItem>
                      <MenuItem value="saladre">Saladre</MenuItem>
                      <MenuItem value="pret">Pret</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <Box sx={{ ml: 2, minWidth: 220 }}>
                  <FormControl sx={{ minWidth: 220 }}>
                    <label className="mt-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Categorie:
                    </label>{" "}
                    <Select
                      value={category}
                      onChange={handleSelectCategory}
                      sx={{ backgroundColor: "#fff" }}
                    >
                      <MenuItem value="plat-grille">Plats-Grilles</MenuItem>
                      <MenuItem value="plat-saute">Plats-Sautés</MenuItem>
                      <MenuItem value="plat-frit">Plats-Frits</MenuItem>
                      <MenuItem value="boisson-jus">Boisson Et Jus</MenuItem>
                      <MenuItem value="flan-dissert">Flan Et Disserts</MenuItem>
                      <MenuItem value="entree-salade">
                        Entrée Et Salades
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            {productList.length > 0 &&
            productList.some((product) => product.productName) ? (
              <div className="m-4 p-6">
                <Stack direction="row" spacing={1}>
                  {productList
                    .filter((product) => product.productName) // Filter out products with null productName
                    .map((data, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-center gap-4"
                      >
                        <Chip
                          label={data.productName}
                          deleteIcon={<DeleteIcon />}
                          onDelete={() => {
                            // Remove the product from the productList
                            setProductList((prevList) =>
                              prevList.filter(
                                (item) => item.productId !== data.productId
                              )
                            );
                          }}
                        />
                        <input
                          type="number"
                          placeholder="qty:"
                          value={data.quantity}
                          onChange={(e) => {
                            const newQuantity = e.target.value;
                            setProductList((prevList) =>
                              prevList.map((item) =>
                                item.productId === data.productId
                                  ? { ...item, quantity: newQuantity }
                                  : item
                              )
                            );
                          }}
                          className="block w-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    ))}
                </Stack>
              </div>
            ) : null}

            <div className="w-90 flex flex-col items-center px-3">
              <label className="ml-12 mb-2 block text-lg font-bold text-black">
                Out Of Stock:
              </label>

              <select
                className="ml-10"
                value={out_of_stock}
                onChange={handleSelectOutOfStock}
              >
                <option value="1">In Stock</option>
                <option value="0">Out Stock</option>
              </select>
            </div>
          </div>

          <div className="right-0 mt-4 flex">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<MdCreate />}
              onClick={() => handleChange()}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <div></div>
    </Fragment>
  );
}

export default UpdPlat;
