import { Fragment, useMemo, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { config_url } from "../../config";
import SelectOpt from "react-select";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {
  RiAddCircleFill,
  RiDeleteBin6Fill,
  RiEdit2Line,
  RiEyeOffLine,
  RiEyeLine,
  RiCheckDoubleFill,
} from "react-icons/ri";
import { Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { detailsProduct } from "../../slices/detailsProduct";
import UpdPlat from "./UpdPlat";
function ListPlats() {
  const { User } = useSelector((state) => state.userInfo);

  const [listProds, setListProds] = useState([]);
  const [listFiltred, setListFiltred] = useState([]);
  console.log(listFiltred);
  const [category, setSelectCategory] = useState("Tous");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowPlat, setRowPlat] = useState({});

  useEffect(() => {
    axios.get(`${config_url}/api/plats`).then(async (res) => {
      await setListProds(res.data);
      await setListFiltred(res.data);
    });
  }, []);
  //Filters
  const filterCategory = (e) => {
    if (listProds.length > 0) {
      setSelectCategory(e.target.value);
      if (listProds.length > 0) {
        const newFilter = listProds.filter(
          (bon) => bon.plat_category === e.target.value
        );
        setListFiltred(newFilter);
        if (e.target.value === "Tous") {
          setListFiltred(listProds);
          return;
        }
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal = (row) => {
    setIsModalOpen(true);
    setRowPlat(row);
  };
  const deletePlat = (id, image, productsContain) => {
    // Filter out invalid products with null product_id
    const validProducts = productsContain.filter(
      (product) => product.product_id !== null
    );

    axios
      .post(
        `${config_url}/api/plats/${id}`,
        {
          image: image,
          productsContain: validProducts, // Only send valid products
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then(() => {
        setListFiltred(listFiltred.filter((row) => row.plat_id !== id));
      })
      .catch((err) => console.error(err));
  };

  function popup(id, fname, image, productsContain) {
    Swal.fire({
      title: "Êtes vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, supprimez " + fname + " ",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePlat(id, image, productsContain);
        Swal.fire("Supprimé!", "Plat a été supprimé.", "success");
      }
    });
  }

  const columns = [
    {
      field: "plat_name",
      headerName: "Plat Nom:",
      headerClassName: "super-app-theme--cell",

      width: 300,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-line",
              overflow: "hidden",
              marginTop: "15px",
            }}
          >
            <Typography>{params.row.plat_name}</Typography>
          </div>
        );
      },
    },
    {
      field: "plat_category",
      headerName: "Category:",
      headerClassName: "super-app-theme--cell",

      width: 140,
    },
    {
      field: "plat_departement",
      headerName: "Departement:",
      headerClassName: "super-app-theme--cell",

      width: 140,
    },
    {
      field: "plat_prix",
      headerName: "Price:",
      headerClassName: "super-app-theme--cell",

      width: 100,
    },
    {
      field: "plat_prix_promo",
      headerName: "Price_Promo",
      headerClassName: "super-app-theme--cell",

      width: 120,
    },

    {
      field: "plat_image",
      headerName: "Image :",
      headerClassName: "super-app-theme--cell",

      width: 100,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundImage: `url(${config_url}/plats/${params.row.plat_image})`,
              width: "50px",
              height: "50px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        );
      },
    },
    {
      field: "modification",
      headerName: "Modifications",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="mt-2 flex items-center justify-center gap-4">
            {User.role === "Super-Admin" && (
              <>
                <div>
                  <RiEdit2Line
                    className="collabListEdit"
                    onClick={() => openModal(params.row)}
                  />
                </div>
                <div>
                  <RiDeleteBin6Fill
                    className="collabListDelete"
                    onClick={() => {
                      popup(
                        params.row.plat_id,
                        params.row.plat_name,
                        params.row.plat_image,
                        params.row.products
                      );
                    }}
                  />
                </div>
              </>
            )}
            <div>
              <Tooltip title="Hiden" placement="top">
                <div>
                  <RiEyeOffLine
                    className="collabListEye"
                    // onClick={() => {
                    //   HidenInStock(params.row.id, params.row.name_es);
                    // }}
                  />
                </div>
              </Tooltip>
            </div>
            {/* <div>
              <Tooltip title="Informations Sur Produirt" placement="top">
                <div>
                  <RiEyeLine
                    className="collabListEdit"
                    // onClick={() => detailsProd(params.row)}
                  />
                </div>
              </Tooltip>
            </div> */}
          </div>
        );
      },
    },
  ];
  return (
    <Fragment>
      {isModalOpen && <UpdPlat closeModal={closeModal} rowPlat={rowPlat} />}

      <div className="page__main">
        <div className="flex items-center justify-center gap-4">
          <Link to="/app/ajoute-plat">
            <Button variant="outlined" startIcon={<RiAddCircleFill />}>
              Add Plat
            </Button>
          </Link>
        </div>

        <div className="w-[180px] bg-white p-4 mt-6 rounded-xl flex items-center justify-between">
          <div>
            <label className="ml-5 mb-2 block text-lg font-bold text-black">
              Category:
            </label>

            <select
              className="ml-5 mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-50 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={category}
              onChange={(e) => filterCategory(e)}
            >
              <option value="Tous">All</option>
              <option value="plat-grille">Plats-Grilles</option>
              <option value="plat-saute">Plats-Sautés</option>
              <option value="plat-frit">Plats-Frits</option>
              <option value="boisson-jus">Boisson Et Jus</option>
              <option value="flan-dissert">Flan Et Disserts</option>
              <option value="entree-salade">Entrée Et Salades</option>
            </select>
          </div>
        </div>

        <h1>List Plats:</h1>

        <Box
          sx={{
            height: "auto",
            width: "auto",
            flex: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& .super-app-theme--cell": {
              backgroundColor: "#fff",
              color: "#1a3e72",
              fontWeight: "bold",
            },
            boxShadow: 2,
            border: 2,
            borderRadius: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
        >
          <DataGrid
            rows={listFiltred}
            columns={columns}
            getRowId={(row) => row.plat_id}
          />
        </Box>
      </div>
    </Fragment>
  );
}

export default ListPlats;
