import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  Departement: [],
  Dressing: [],
  Controller: [],
};

// Create slice
const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addDepartement: (state, action) => {
      state.Departement.push(action.payload);
    },
    addDressing: (state, action) => {
      state.Dressing.push(action.payload);
    },
    addController: (state, action) => {
      state.Controller.push(action.payload);
    },
  },
});

// Export actions
export const { addDepartement, addDressing, addController } =
  historySlice.actions;

// Selectors
export const selectDepartement = (state) => state.history.Departement;
export const selectDressing = (state) => state.history.Dressing;
export const selectController = (state) => state.history.Controller;

// Export reducer
export default historySlice.reducer;
