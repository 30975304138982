import React, { useEffect, useState, Fragment } from "react";
import io from "socket.io-client";
import { config_url } from "../config";
import Button from "@mui/material/Button";

import axios from "axios";
import { useSelector } from "react-redux";
import { getOrdersByDepartment } from "../services/api";
import {
  MdAutorenew,
  MdBackHand,
  MdSoupKitchen,
  MdArrowBack,
} from "react-icons/md";

const DepartmentDashboard = ({ department }) => {
  const { User } = useSelector((state) => state.userInfo);
  const [orders, setOrders] = useState([]);
  console.log(orders);
  const [displayButton, setDisplayButton] = useState(false);
  const [displayButtonStatus, setDisplayButtonStatus] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date()); // State for current time

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  // Fetch orders from the department
  const actuallyOrders = async () => {
    setDisplayButton(true);
    try {
      const response = await getOrdersByDepartment(department);
      const filteredOrders = response.data.orders.filter(
        (order) => order.status !== "dressing" && order.status !== "ready"
      );
      setOrders(filteredOrders);
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch existing orders
  const fetchOldOrders = async () => {
    try {
      const response = await getOrdersByDepartment(department);
      setOrders(response.data.orders);
      setDisplayButton(false);
    } catch (error) {
      console.error(error);
    }
  };

  const backToMoment = () => {
    actuallyOrders();
    setDisplayButton(true);
  };

  useEffect(() => {
    const socket = io(config_url, {
      transports: ["websocket"], // Ensure WebSocket transport is used
      withCredentials: true, // Include credentials if your server requires them
    });
    socket.emit("join-department", department);

    socket.on("new-order", (order) => {
      setOrders((prevOrders) => [...prevOrders, order]);
    });

    socket.on("order-updated", (updatedOrder) => {
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === updatedOrder.id ? updatedOrder : order
        )
      );
    });

    const fetchOrders = async () => {
      try {
        const response = await getOrdersByDepartment(department);
        const filteredOrders = response.data.orders.filter(
          (order) => order.status !== "dressing" && order.status !== "ready"
        );
        setOrders(filteredOrders);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrders();

    return () => {
      socket.disconnect(); // Disconnect the socket
    };
  }, [department]);
  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 6000); // Update every 60 seconds
    return () => clearInterval(timer); // Cleanup timer on unmount
  }, []);
  // Function to calculate the difference in minutes between the order time and current time
  const calculateTimeDifference = (orderTime) => {
    const orderDate = new Date(orderTime);
    const timeDiff = Math.abs(currentTime - orderDate);
    const minutes = Math.floor(timeDiff / 1000 / 60); // Only calculate minutes
    return `${minutes} minutes ago`;
  };

  const updateOrderStatus = async (order_num, orderId, status, date_order) => {
    const currentTime = formatDate(new Date()); // Format the current time to "yyyy-mm-dd hh:mm"
    const retardTime = calculateTimeDifference(date_order); // Assuming the order has a `date_order` field

    try {
      const response = await axios.patch(
        `${config_url}/api/orders/${orderId}`,
        {
          status: status,
          date_order: currentTime, // Use the formatted date
        }
      );

      console.log(response.data.message);

      if (status === "dressing") {
        setOrders((prevOrders) =>
          prevOrders.filter((order) => order.status !== "dressing")
        );
        setDisplayButtonStatus(false);
      } else {
        setDisplayButtonStatus(true);
      }

      await axios.post(`${config_url}/api/statistics`, {
        retard_time: retardTime,
        status: status,
        department: department, // Assuming department is passed as prop or defined
        date_order: currentTime,
        order_num: order_num, // Assuming the order has an id or order number
      });
      // Update the order's date_order to the current formatted time
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, date_order: currentTime } : order
        )
      );
    } catch (error) {
      console.error(
        "Error updating order status:",
        error.response.data.message
      );
    }
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case "pending":
        return {
          backgroundColor: "rgba(255, 165, 0, 0.2)", // Orange background
          color: "orange",
        };
      case "en-cours":
        return {
          backgroundColor: "rgba(0, 123, 255, 0.2)", // Blue background
          color: "blue",
        };
      case "dressing":
        return {
          backgroundColor: "rgba(255, 0, 0, 0.2)", // Red background
          color: "red",
        };
      case "pret":
        return {
          backgroundColor: "rgba(0, 128, 0, 0.2)", // Green background
          color: "green",
        };
      default:
        return {
          backgroundColor: "rgba(0, 0, 0, 0.2)", // Default background
          color: "black",
        };
    }
  };

  const ButtonComponent = ({ displayButton, fetchOldOrders, backToMoment }) => {
    console.log("ButtonComponent render", displayButton);
    return displayButton ? (
      <Button
        variant="outlined"
        endIcon={<MdAutorenew />}
        onClick={() => fetchOldOrders()}
      >
        Load History
      </Button>
    ) : (
      <Button
        variant="outlined"
        endIcon={<MdArrowBack />}
        onClick={() => backToMoment()}
      >
        Back History
      </Button>
    );
  };

  return (
    <Fragment>
      <div className="w-full flex min-h-screen items-center justify-center bg-white">
        <div className="p-6 overflow-scroll px-0">
          <div className="flex m-10">
            <ButtonComponent
              displayButton={displayButton}
              fetchOldOrders={fetchOldOrders}
              backToMoment={backToMoment}
            />
          </div>

          <table className="w-full table-auto text-left">
            <thead>
              <tr>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  Order Num
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  Table
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  Order Time
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  Plat Name
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  Status
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  Time Difference
                </th>
                <th className="text-center border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={`${order.id}`}>
                  <td className="p-4 border-b border-blue-gray-50">
                    {order.order_num}
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    {order.table_num}
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    {order.date_order}
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="flex items-center gap-3">
                      {order.plat_name}
                      <span className="inline-flex items-center justify-center w-6 h-6 ms-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                        x {order.qty}
                      </span>
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="w-max">
                      <div
                        className="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none py-1 px-2 text-xs rounded-md"
                        style={getStatusStyles(order.status)}
                      >
                        <span>{order.status}</span>
                      </div>
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    {calculateTimeDifference(order.date_order)}
                  </td>
                  <td className="flex items-center jsutify-center gap-4 p-4 border-b border-blue-gray-50">
                    {/* First button is always visible */}

                    <Button
                      variant="outlined"
                      endIcon={<MdBackHand />}
                      onClick={() =>
                        updateOrderStatus(
                          order.order_num,
                          order.id,
                          "en-cours",
                          order.date_order
                        )
                      }
                    >
                      En-coure
                    </Button>

                    <Button
                      variant="outlined"
                      endIcon={<MdSoupKitchen />}
                      onClick={() =>
                        updateOrderStatus(
                          order.order_num,
                          order.id,
                          "dressing",
                          order.date_order
                        )
                      }
                    >
                      Ready To Dressing
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default DepartmentDashboard;
