import axios from "axios";
import { config_url } from "../config";
const API_URL = `${config_url}/api`;

export const placeOrder = async (items, department) => {
  return await axios.post(`${API_URL}/orders`, { items, department });
};

export const getOrdersByDepartment = async (department) => {
  return await axios.get(`${API_URL}/orders/${department}`);
};
export const getOrdersByStatus = async (status) => {
  return await axios.get(`${API_URL}/orders/status/${status}`);
};
export const getOrders = async () => {
  return await axios.get(`${API_URL}/orders`);
};
// get Orders by Cart just orders they missed the time 1 houre
export const getOrdersOld = async () => {
  return await axios.get(`${API_URL}/orders/missed_befor_houre/cart`);
};
