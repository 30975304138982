import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { config_url } from "../config";
import { MdLogin } from "react-icons/md";

import axios from "axios";
import { toast } from "react-toastify";
import "./users.css";
import { detailsUser } from "../slices/userInfo";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const defaultTheme = createTheme();

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const email = useRef();
  const password = useRef();
  const [showPassword, setShowPassword] = useState(false);

  const loginApp = async (event) => {
    event.preventDefault();

    if (email.current.value === "" || password.current.value === "") {
      toast.info("Please fill in the information");
      return;
    }

    try {
      const response = await axios.post(`${config_url}/api/users/login`, {
        email: email.current.value,
        password: password.current.value,
      });
      console.log(response.data);

      if (response.data.success === 0) {
        toast.warn("Invalid User");
        return;
      } else {
        dispatch(detailsUser(response.data.results));
        localStorage.setItem("token", response.data.token);

        const userRole = response.data.results.role;
        if (userRole === "Admin" || userRole === "Super-Admin") {
          navigate("/app/list-produits");
        } else if (userRole === "Friteuse") {
          navigate("/department/friteuse");
        } else if (userRole === "Plancha") {
          navigate("/department/plancha");
        } else if (userRole === "Saladerie") {
          navigate("/department/salade");
        } else if (userRole === "Dressing") {
          navigate("/dressing");
        } else if (userRole === "Fornisseur") {
          navigate("/app/controlle-produits-fornisseur");
        } else if (userRole === "Controller") {
          navigate("/controlle");
        } else if (userRole === "Waiter") {
          navigate("/tables");
        }

        dispatchEvent(new Event("storage"));
      }
    } catch (error) {
      toast.error("An error occurred while logging in.");
      console.error("Login error:", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src="/DON-CALAMAR.jpg" alt="logo-doncalamar" />
          <Typography component="h1" variant="h5">
            Don Calamar Groupe
          </Typography>
          <Box component="form" noValidate onSubmit={loginApp} sx={{ mt: 1 }}>
            <TextField
              inputRef={email}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              inputRef={password}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<MdLogin />}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
