import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { Task } from "./Task";

import "./Column.css";

export const Column = ({ listProds }) => {
  return (
    <div className="column">
      <SortableContext items={listProds} strategy={verticalListSortingStrategy}>
        {listProds?.map((task) => (
          <Task
            key={task.id}
            id={task.id}
            title={task.name_es}
            image={task.image}
            category={task.category}
            priority={task.priority}
          />
        ))}
      </SortableContext>
    </div>
  );
};
