import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

import Paper from "@mui/material/Paper";
import { RiDeleteBin6Fill, RiAddCircleFill } from "react-icons/ri";
import { config_url } from "../../config";
import UpdProblem from "./UpdProblem";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

function ListProdsDefaut() {
  const [listProdsDefaut, setListProdsDefaut] = useState([]);
  console.log(listProdsDefaut);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowProblem, setRowProblem] = useState({});
  const [statisticsProductDefauts, setStatisticsProductDefauts] = useState([]);

  useEffect(() => {
    axios
      .get(`${config_url}/api/produits_defaut`)
      .then((res) => {
        if (Array.isArray(res.data)) {
          setListProdsDefaut(res.data);
        } else {
          console.error(
            "Invalid data structure received for categories:",
            res.data
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  useEffect(() => {
    // Fetch data from the backend
    axios
      .get(`${config_url}/api/stock-problem/stats`)
      .then((response) => {
        setStatisticsProductDefauts(response.data); // Set the response data to state
      })
      .catch((error) => {
        console.error("Error fetching stock and problem stats:", error);
      });
  }, []);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal = (row) => {
    setIsModalOpen(true);
    setRowProblem(row);
  };

  return (
    <div>
      {isModalOpen && (
        <UpdProblem closeModal={closeModal} rowProblem={rowProblem} />
      )}

      <div className="w-full container mx-auto my-8">
        <table className="w-full table-auto border-collapse border border-gray-300 rounded-2xl overflow-hidden">
          <thead>
            <tr className="text-black">
              <th className="py-2 px-4 border border-gray-300 bg-gray-200">
                Produit
              </th>
              <th className="py-2 px-4 border border-gray-300 bg-gray-200">
                Problems
              </th>
              <th className="py-2 px-4 border border-gray-300 bg-gray-200">
                Date Created
              </th>
              <th className="py-2 px-4 border border-gray-300 bg-gray-200">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {listProdsDefaut?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border border-gray-300">
                  {item.product_name}
                </td>
                <td className="py-2 px-4 border border-gray-300">
                  <Paper
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      listStyle: "none",
                      p: 0.5,
                      m: 0,
                    }}
                    component="ul"
                  >
                    {item.problems?.map((data, index) => {
                      return (
                        <ListItem key={index}>
                          <Chip
                            label={`${data.problem} - Qty Losed: ${data.qty_losed}, Qty Found: ${data.quantity_trouver}`}
                            // Optional: Add onClick handler for Chip if you want the entire chip clickable
                            // onClick={() => handleEditProblem(data)}
                          />
                          {/* Add an "Edit" button beside the Chip */}
                          <button
                            className="text-sm bg-blue-500 text-white px-2 py-1 rounded"
                            onClick={() => openModal(data)}
                          >
                            Edit
                          </button>
                        </ListItem>
                      );
                    })}
                  </Paper>
                </td>
                <td className="py-2 px-4 border border-gray-300">
                  {item.date_created}
                </td>
                <td className="py-2 px-4 border border-gray-300">
                  <div className="flex items-center justify-center">
                    <RiDeleteBin6Fill
                      className="collabListDelete"
                      // onClick={() =>
                      //   handleDeleteGroupe(item.group_id)
                      // }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ width: "100%", height: 400 }}>
        <h2>Product Loss and Stock Statistics</h2>
        <ResponsiveContainer>
          <BarChart
            data={statisticsProductDefauts}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="product_name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="total_lost" fill="#ff7300" name="Total Lost" />
            <Bar
              dataKey="stock_restant"
              fill="#387908"
              name="Remaining Stock"
            />
            <Bar
              dataKey="problem_occurrences"
              fill="#8884d8"
              name="Problem Occurrences"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default ListProdsDefaut;
