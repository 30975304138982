import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { config_url } from "../../config";
import {
  setAddItemToCart,
  setDecreaseItemQTY,
  setIncreaseItemQTY,
} from "../../slices/cartSlice";
import Cart from "./Cart";
import { AiOutlineClose } from "react-icons/ai";
import { MdAdd, MdAddCircleOutline } from "react-icons/md";

const steps = ["Plats", "Boissons", "Flan Et Entree", "Commander"];
const stepCategories = [
  ["ALL", "plat-saute", "plat-grille", "plat-frit"], // Plats
  ["boisson-jus"], // Boissons
  ["ALL", "flan-dissert", "entree-salade"], // Flan Et Entree
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "90%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "90%",
  borderRadius: "12px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ReservationModel({
  openReservation,
  setOpenReservation,
  handleCloseReservation,
}) {
  const Details = useSelector((state) => state.Load);
  const { Col } = Details;
  const cartItems = useSelector((state) => state.cart.cartItems || []);

  const dispatch = useDispatch();

  const [listFiltred, setListFiltred] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [filteredCategory, setFilteredCategory] = useState(null);

  const onAddToCart = (item) => {
    dispatch(
      setAddItemToCart({
        plat_id: item.plat_id,
        plat_name: item.plat_name,
        prix: item.plat_prix,
        prix_promo: item.plat_prix_promo,
        image: item.plat_image,
        plat_departement: item.plat_departement,
        products_contain: item.products,
      })
    );
  };

  const onIncreaseItemQty = (plat_id) => {
    dispatch(setIncreaseItemQTY({ plat_id: plat_id }));
  };

  const onDecreaseItemQty = (plat_id) => {
    dispatch(setDecreaseItemQTY({ plat_id: plat_id }));
  };

  useEffect(() => {
    filterItemsByCategory(0);
  }, [Col]);

  const filterItemsByCategory = (stepIndex) => {
    setFilteredCategory(null); // Reset the filtered category

    if (stepIndex < stepCategories.length) {
      const categories = stepCategories[stepIndex];

      // If "ALL" is clicked, filter all items belonging to only the current step's categories
      const filteredItems = Col.filter((item) =>
        categories.includes(item.plat_category)
      );
      setListFiltred(filteredItems);
    } else {
      setListFiltred([]); // Clear the filtered list when reaching the "Commander" step
    }
  };

  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex);
    filterItemsByCategory(stepIndex);
  };

  const handleCategoryClick = (category) => {
    // If "ALL" is clicked, filter all items in the current step
    if (category === "ALL") {
      setFilteredCategory(null); // Reset to show all items in the current step
      filterItemsByCategory(activeStep); // Call the filtering function for the current step
    } else {
      setFilteredCategory(category); // Show only items for the selected category
    }
  };

  const displayedItems = filteredCategory
    ? listFiltred.filter((item) => item.plat_category === filteredCategory)
    : listFiltred;

  const isItemInCart = (plat_id) => {
    return cartItems.find((cartItem) => cartItem.plat_id === plat_id);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openReservation}
      onClose={handleCloseReservation} // Change here to ensure modal closes properly
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openReservation}>
        <Box sx={style}>
          <AiOutlineClose
            onClick={handleCloseReservation}
            className="fixed right-2 top-2 text-3xl text-white bg-red-500 rounded p-1 mb-10 ml-5"
          />

          <Box sx={{ width: "100%", mt: "20px" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} onClick={() => handleStepClick(index)}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length - 1 ? (
              <Cart onClose={setOpenReservation} />
            ) : (
              <React.Fragment>
                <div className="flex flex-wrap justify-center gap-2 mt-4">
                  {stepCategories[activeStep].map((category) => (
                    <Button
                      key={category}
                      variant={
                        filteredCategory === category ||
                        (category === "ALL" && filteredCategory === null)
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => handleCategoryClick(category)}
                    >
                      {category === "ALL" ? "All" : category}{" "}
                      {/* Display "All" for the ALL button */}
                    </Button>
                  ))}
                </div>

                <div className="bg-white">
                  <div className="mx-auto max-w-7xl overflow-hidden sm:px-6 lg:px-8">
                    <div className="-mx-px grid grid-cols-2 border-l border-gray-200 sm:mx-0 md:grid-cols-2 lg:grid-cols-4">
                      {displayedItems.map((item) => {
                        const cartItem = isItemInCart(item.plat_id);
                        return (
                          <div
                            key={item.plat_id}
                            className="group relative border-b border-r border-gray-200 p-4 sm:p-6"
                          >
                            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-200 group-hover:opacity-75">
                              <img
                                alt={item.plat_name}
                                src={`${config_url}/plats/${item.plat_image}`}
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="pb-4 pt-10 text-center">
                              <h3 className="text-sm font-medium text-gray-900">
                                <a>
                                  <span
                                    aria-hidden="true"
                                    className="absolute inset-0"
                                  />
                                  {item.plat_name}
                                </a>
                              </h3>
                              <div className="mt-3 flex flex-col items-center">
                                {item.plat_prix_promo !== 0 ? (
                                  <>
                                    <p className="mt-1 text-sm text-cyan-600 font-bold">
                                      {item.plat_prix_promo} Dh
                                    </p>
                                    <p className="mt-1 text-sm text-gray-700 line-through">
                                      {item.plat_prix} Dh
                                    </p>
                                  </>
                                ) : (
                                  <p className="mt-6 text-sm text-cyan-600 font-bold">
                                    {item.plat_prix} Dh
                                  </p>
                                )}
                              </div>
                              <div>
                                {cartItem ? (
                                  <div className="flex items-center justify-around gap-2 w-full">
                                    <button
                                      className="button-cart-inc-dec"
                                      type="button"
                                      onClick={() =>
                                        onDecreaseItemQty(cartItem.plat_id)
                                      }
                                    >
                                      <img
                                        src="/minus.png"
                                        alt="minus-icon"
                                        width="20"
                                      />
                                    </button>
                                    <div className="bg-theme-cart rounded text-white font-medium lg:text-xs w-7 h-6 lg:h-5 lg:w-6 flex items-center justify-center">
                                      {cartItem.cartQuantity}
                                    </div>
                                    <button
                                      className="button-cart-inc-dec"
                                      type="button"
                                      onClick={() =>
                                        onIncreaseItemQty(cartItem.plat_id)
                                      }
                                    >
                                      <img
                                        src="/plus.png"
                                        alt="plus-icon"
                                        width="20"
                                      />
                                    </button>
                                  </div>
                                ) : (
                                  <Button
                                    className="w-full mt-5"
                                    variant="contained"
                                    startIcon={<MdAddCircleOutline />}
                                    onClick={() => onAddToCart(item)}
                                  >
                                    Add to Cart
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ReservationModel;
