import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrdersOld } from "../../services/api";
import {
  setClearCartItems,
  setDecreaseItemQTY,
  setIncreaseItemQTY,
  setRemoveItemFromCart,
} from "../../slices/cartSlice";
import { config_url } from "../../config";
import CartEmpty from "./CartEmpty";
import Drawer from "@mui/material/Drawer";
import { MdAccessTime } from "react-icons/md";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Custom MUI Theme to ensure Drawer has higher z-index
const theme = createTheme({
  zIndex: {
    drawer: 1301, // Ensuring drawer is above modal (modal z-index = 1300)
  },
});

function Cart({ onClose }) {
  const { User } = useSelector((state) => state.userInfo);
  const [maxOrderNum, setMaxOrderNum] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date()); // State for current time

  const { cartItems, table } = useSelector((state) => state.cart);
  const [orderList, setOrderList] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const dispatch = useDispatch();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const onRemoveItem = (item) => {
    const { plat_id, plat_name } = item;
    dispatch(
      setRemoveItemFromCart({
        plat_id,
        plat_name,
      })
    );
  };

  const onIncreaseItemQTY = (plat_id) => {
    dispatch(setIncreaseItemQTY({ plat_id }));
  };

  const onDecreaseItemQTY = (plat_id) => {
    dispatch(setDecreaseItemQTY({ plat_id }));
  };

  useEffect(() => {
    if (cartItems) {
      const updatedProductList = cartItems?.map((data) => ({
        plat_id: data.plat_id,
        plat_name: data.plat_name,
        qty: data.cartQuantity,
        total_price:
          data.prix_promo === 0
            ? data.prix * data.cartQuantity
            : data.prix_promo * data.cartQuantity,
        department: data.plat_departement,
        products_contain: data.products_contain,
      }));
      setOrderList(updatedProductList);
    }
  }, [cartItems]);

  useEffect(() => {
    const fetchMaxOrderNum = async () => {
      try {
        const response = await fetch(`${config_url}/api/max-order-num`);
        const data = await response.json();
        setMaxOrderNum(data.maxOrderNum);
      } catch (error) {
        console.error("Error fetching max order number:", error);
      }
    };

    fetchMaxOrderNum();
  }, []);

  const sendOrder = () => {
    const apiData = {
      items: [
        {
          id: 13,
          user_id: User.id,
          num_order: maxOrderNum + 1,
          date_order: "2024-08-26 23:00",
          plats: orderList,
        },
      ],
      table: table.name,
    };

    fetch(`${config_url}/api/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        dispatch(setClearCartItems());
      })
      .catch((error) => {
        console.error("Error sending order:", error);
      });
  };

  const fetchOldOrders = async () => {
    await setOpen(true);
    const fetchOrders = async () => {
      try {
        const response = await getOrdersOld();
        setOrders(response.data.orders);
        setFilteredOrders(response.data.orders);
      } catch (error) {
        console.error(error);
      }
    };
    fetchOrders();
  };
  const calculateTimeDifference = (orderTime) => {
    const orderDate = new Date(orderTime);
    const timeDiff = Math.abs(currentTime - orderDate);
    const minutes = Math.floor(timeDiff / 1000 / 60); // Only calculate minutes
    return `${minutes} minutes ago`;
  };

  const DrawerList = (
    <Box
      sx={{ mt: 20, width: 800, zIndex: 1301 }} // Set zIndex for drawer
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <div className="flex items-center justify-center mb-10">
        <button
          type="button"
          onClick={() => toggleDrawer(false)}
          className="button-theme bg-gradient-to-b from-amber-500 to-orange-500 shadow-lg shadow-orange-500 flex items-center justify-center text-slate-900 py-2 gap-3 text-sm px-5 font-semibold active:scale-110"
        >
          <span>Retour Au Menu</span>
          <ArrowRightIcon className="w-5 h-5 text-slate-900" />
        </button>
      </div>
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
              <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                Order Num
              </p>
            </th>
            <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
              <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                Table
              </p>
            </th>

            <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
              <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                Time Difference
              </p>
            </th>
            <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
              <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                Order Details
              </p>
            </th>
            <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
              <p className="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                Departement
              </p>
            </th>
          </tr>
        </thead>
        <tbody className="text-2xl">
          {filteredOrders.map((order) => (
            <tr key={`${order.id}`}>
              <td className="p-4 border-b border-blue-gray-50">
                <div className="flex items-center gap-3">
                  <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
                    {order.order_num}
                  </p>
                </div>
              </td>
              <td className="p-4 border-b border-blue-gray-50">
                <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                  {order.table_num}
                </p>
              </td>

              <td className="p-4 border-b border-blue-gray-50">
                <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                  <MdAccessTime className="me-1.5" size={20} />
                  {calculateTimeDifference(order.date_order)}
                </span>
              </td>
              <td className="p-4 border-b border-blue-gray-50">
                <div className="flex items-center gap-3">
                  {order.plat_name}
                  <span className="inline-flex items-center justify-center w-6 h-6 ms-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                    x {order.qty}
                  </span>
                </div>
              </td>
              <td className="p-4 border-b border-blue-gray-50">
                <div className="flex items-center gap-3">
                  <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                    {order.department}
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <div className="flex flex-col min-h-screen items-center mt-10 bg-white">
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={() => sendOrder()}
          >
            Send Order
          </Button>
          <div>
            <Button onClick={() => fetchOldOrders()}>Backup History</Button>
            <Drawer open={open} onClose={toggleDrawer(false)}>
              {DrawerList}
            </Drawer>
          </div>
          {cartItems?.length === 0 ? (
            <div className="flex items-center justify-center ml-10 mt-10">
              <CartEmpty onClose={onClose} />
            </div>
          ) : (
            <div className="w-full p-6 overflow-scroll px-0">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                      Image
                    </th>
                    <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                      Items
                    </th>
                    <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                      Department
                    </th>
                    <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                      Quantity
                    </th>
                    <th className="flex items-center justify-center border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {cartItems.map((item) => (
                    <tr key={`${item.plat_id}`}>
                      <td className="p-4 border-b border-blue-gray-50">
                        <img
                          src={`${config_url}/plats/${item.image}`}
                          className="w-10 h-10"
                        />
                      </td>
                      <td className="p-4 border-b border-blue-gray-50">
                        <div className="w-max">
                          <div className="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-green-500/20 text-green-900 py-1 px-2 text-xs rounded-md">
                            <span>{item.plat_name}</span>
                          </div>
                        </div>
                      </td>
                      <td className="p-4 border-b border-blue-gray-50">
                        <div className="w-max">
                          <div className="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-green-500/20 text-green-900 py-1 px-2 text-xs rounded-md">
                            <span>{item.plat_departement}</span>
                          </div>
                        </div>
                      </td>
                      <td className="p-4 border-b border-blue-gray-50">
                        {item.cartQuantity}
                      </td>
                      <td className="flex items-center justify-center gap-4 p-4 border-b border-blue-gray-50">
                        <div className="flex items-center justify-around gap-2 w-full">
                          <button
                            type="button"
                            onClick={() => onDecreaseItemQTY(item.plat_id)}
                          >
                            <img src="/minus.png" alt="minus-icon" width="20" />{" "}
                          </button>
                          <div className="bg-theme-cart rounded text-white font-medium lg:text-xs w-7 h-6 lg:h-5 lg:w-6 flex items-center justify-center">
                            {item.cartQuantity}
                          </div>
                          <button
                            type="button"
                            onClick={() => onIncreaseItemQTY(item.plat_id)}
                          >
                            <img src="/plus.png" alt="plus-icon" width="20" />{" "}
                          </button>
                        </div>{" "}
                        <button
                          type="button"
                          onClick={() => onRemoveItem(item)}
                        >
                          <img
                            src="/delete.png"
                            alt="delete-icon"
                            width="25"
                            className="text-red-500"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Fragment>
    </ThemeProvider>
  );
}

export default Cart;
