import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import DetailsSlice from "../slices/detailsProduct";
import UserInfo from "../slices/userInfo";
import cartSlice from "../slices/cartSlice";
import historySlice from "../slices/historySlice";

// Combine your slices into a single reducer
const rootReducer = combineReducers({
  Load: DetailsSlice,
  userInfo: UserInfo,
  cart: cartSlice,
  history: historySlice,
});

// Create a persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
