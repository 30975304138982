import React, { useState, Fragment, useEffect, useRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SelectOpt from "react-select";
import "react-image-crop/dist/ReactCrop.css";

import { toast } from "react-toastify";
import {
  MdCloudUpload,
  MdDelete,
  MdOutlineSend,
  MdDeleteForever,
} from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import { config_url } from "../../config";
function AddPlat() {
  const [listProds, setListProds] = useState([]);
  const [items, setItems] = useState([]); // Array to store list items
  console.log("items:", items);
  let countRef = useRef(0); // useRef to persist count across renders

  console.log(listProds);
  const [name, setName] = useState("");
  const [prix, setPrix] = useState(0);

  const [category, setSelectCategory] = useState("plat-grille");
  const [departement, setDepartement] = useState("plancha");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [prix_promo, setPrixPromo] = useState(0);
  const [image, setImage] = useState("");
  console.log(image);
  const [fileName, setFileName] = useState("No selected file");

  const [loading, setLoading] = useState(false);

  const handleUpload = async (e) => {
    await setLoading(true);

    const formdata = new FormData();
    formdata.append("image_plat", image);
    formdata.append("categories", JSON.stringify(items));

    formdata.append("name", name);
    formdata.append("category", category);
    formdata.append("departement", departement);
    formdata.append("prix", prix);
    formdata.append("prix_promo", prix_promo);

    try {
      const response = await axios.post(
        `${config_url}/api/create-plat`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200)
        toast.success("Ajoute Produit Success !!", {
          position: "top-right",
        });
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    axios
      .get(`${config_url}/api/products`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then(async (res) => {
        await setListProds(res.data);
      });
  }, []);

  const selOptions = [];
  const ids = listProds?.map((o) => o.name);
  const filtered = listProds?.filter(
    ({ name }, index) => !ids?.includes(name, index + 1)
  );

  for (let i = 0; i < filtered.length; i++) {
    if (filtered.length > 0) {
      selOptions.push({
        value: filtered[i].name,
        label: filtered[i].name,
        id: filtered[i].id,
      });
    }
  }
  const handleSelectDepartement = (e) => {
    setDepartement(e.target.value);
  };

  const handleSelectCategory = (e) => {
    setSelectCategory(e.target.value);
  };
  const handle = (e) => {
    const selectedItems = e.map((option) => ({
      id: option.id,
      value: option.value || option.label, // Ensure you capture both value and label correctly
    }));

    setItems(selectedItems);
    setSelectedCategories(e);
  };

  const handleInputChange = (event, index) => {
    const newItems = [...items];
    newItems[index].value = event.target.value;
    setItems(newItems);
  };
  // Handler for quantity input change
  const handleQuantityChange = (event, index) => {
    const newItems = [...items];
    newItems[index].quantity = event.target.value;
    setItems(newItems);
  };

  // Handler for removing list item
  const removeListItem = (id) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };
  const handleRemoveOption = (removedValue) => {
    // Just filter the items to remove the selected one, don't touch the selOptions
    setItems((prevItems) =>
      prevItems.filter((item) => item.value !== removedValue)
    );
  };

  const customMultiValue = (props) => (
    <div className="flex items-center justify-center gap-2 ml-2 font-bold bg-gray-300 rounded-2xl p-1">
      <div>{props.data.label}</div>
      <button
        onClick={(e) => {
          props.removeProps.onClick();
          handleRemoveOption(props.data.label); // Only update the selected items
        }}
        className="w-8 h-8 rounded-full text-black p-1 bg-red-400"
      >
        X
      </button>
    </div>
  );

  return (
    <Fragment>
      <div className="page__main">
        <div className="ml-[10%]">
          <button
            type="button"
            className="relative w-full flex justify-center mb-10 items-center px-5 py-2.5 font-medium tracking-wide text-white capitalize bg-cyan-500 rounded-md  focus:outline-none   transition duration-300 transform active:scale-95 ease-in-out"
          >
            <span className="pl-2 mx-1">Page For Add Plat</span>
          </button>

          <form
            className="mb-8 p-8 text-xl bg-white rounded-3xl boxShadow"
            onSubmit={handleUpload}
          >
            <h1 className="text-center">Information About Plat:</h1>

            <div className="bg-gray-200 p-3 rounded-2xl flex flex-wrap -mx-3 mb-6 mx-auto max-w-[1200px]">
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Name:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-sky-500"
                  type="text"
                  name="nom"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name Product:"
                />
              </div>

              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Prix:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-sky-500"
                  type="number"
                  onChange={(e) => setPrix(e.target.value)}
                  placeholder="Prix:"
                />
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Prix Promo:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-sky-500"
                  type="number"
                  onChange={(e) => setPrixPromo(e.target.value)}
                  placeholder="Prix Promo:"
                />
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <Box sx={{ ml: 2, minWidth: 220 }}>
                  <FormControl sx={{ minWidth: 220 }}>
                    <label className="mt-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Departement:
                    </label>{" "}
                    <Select
                      value={departement}
                      onChange={handleSelectDepartement}
                      sx={{ backgroundColor: "#fff" }}
                    >
                      <MenuItem value="plancha">Plancha</MenuItem>
                      <MenuItem value="friteuse">Friteuse</MenuItem>
                      <MenuItem value="saladre">Saladre</MenuItem>
                      <MenuItem value="pret">Pret</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <Box sx={{ ml: 2, minWidth: 220 }}>
                  <FormControl sx={{ minWidth: 220 }}>
                    <label className="mt-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Categorie:
                    </label>{" "}
                    <Select
                      value={category}
                      onChange={handleSelectCategory}
                      sx={{ backgroundColor: "#fff" }}
                    >
                      <MenuItem value="plat-grille">Plats-Grilles</MenuItem>
                      <MenuItem value="plat-saute">Plats-Sautés</MenuItem>
                      <MenuItem value="plat-frit">Plats-Frits</MenuItem>
                      <MenuItem value="boisson-jus">Boisson Et Jus</MenuItem>
                      <MenuItem value="flan-dissert">Flan Et Disserts</MenuItem>
                      <MenuItem value="entree-salade">
                        Entrée Et Salades
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <hr className="border-4 rounded-3xl border-sky-800 border-dashed" />

            <h1 className="text-center text-2xl font-semibold">
              Upload Image,Images And Video
            </h1>

            <div className="w-full flex flex-wrap justify-center -mx-3 mb-6  max-w-[1400px] gap-6">
              <div className="grid gap-4">
                <div
                  className="form"
                  onClick={() => document.querySelector(".input-field").click()}
                >
                  <input
                    type="file"
                    className="input-field"
                    hidden
                    onChange={({ target: { files } }) => {
                      files[0] && setFileName(files[0].name);
                      if (files) {
                        setImage(files[0]);
                      }
                    }}
                  />

                  {image ? (
                    <img
                      className="w-[150px] h-[150px] rounded-full border-2 border-gray-400"
                      src={URL.createObjectURL(image)}
                      width={150}
                      height={150}
                      alt={fileName}
                    />
                  ) : (
                    <>
                      <MdCloudUpload color="#1475cf" size={60} />
                      <p>Principal Image to upload</p>
                    </>
                  )}
                </div>

                <section className="uploaded-row">
                  <AiFillFileImage color="#1475cf" />
                  <span className="upload-content">
                    {fileName} -
                    <MdDelete
                      onClick={() => {
                        setFileName("No selected File");
                        setImage(null);
                      }}
                    />
                  </span>
                </section>
              </div>
              <div className="flex items-center justify-center bg-gray-100 rounded-3xl gap-4 mb-10 pl-8 pr-8">
                <div className="flex gap-4 mb-8">
                  <div className="flex flex-col items-center">
                    <span className="text-black font-bold">
                      Products Contain :
                    </span>
                    <SelectOpt
                      className="selOptions"
                      options={selOptions}
                      isMulti
                      onChange={handle}
                      components={{ MultiValue: customMultiValue }}
                    />
                  </div>
                </div>
                <ul>
                  {items.map((item, index) => (
                    <li
                      key={item.id}
                      className="flex items-center space-between gap-4"
                    >
                      <input
                        type="text"
                        className="w-50 appearance-none block bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-sky-500"
                        value={item.value}
                        onChange={(event) => handleInputChange(event, index)}
                        placeholder={`Product ${index + 1}`}
                      />
                      <input
                        type="number"
                        step="0.01" // Allows decimals like 1.5, 2.75, etc.
                        className="w-50 appearance-none block bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-sky-500"
                        value={item.quantity}
                        onChange={(event) => handleQuantityChange(event, index)}
                        placeholder="Quantity"
                        min="0" // Prevent negative values if desired
                      />
                      <MdDeleteForever
                        size={50}
                        className="text-red-500 ml-2"
                        onClick={() => removeListItem(item.id)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <button
              className="flex items-center gap-2 ml-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              type="submit"
            >
              {loading && <i className="fa fa-refresh fa-spin"></i>}
              <span>Save</span>
              <span>
                <MdOutlineSend />
              </span>
            </button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default AddPlat;
