import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Col: {},
  Details: {},
  Langue: {},
};

const detaildSlice = createSlice({
  initialState,
  name: "Col",
  reducers: {
    detailsProduct: (state, action) => {
      console.log(action.payload);
      state.Col = action.payload;
    },
    loadDetailsProduct: (state, action) => {
      console.log(action.payload);
      state.Details = action.payload;
    },
    loadLangue: (state, action) => {
      console.log(action.payload);
      state.Langue = action.payload;
    },
  },
});

export const { detailsProduct, loadDetailsProduct, loadLangue } =
  detaildSlice.actions;

export const Col = (state) => state.Load.Col;
export const Details = (state) => state.Load.Details;
export const Langue = (state) => state.Load.Langue;

export default detaildSlice.reducer;
