import { Fragment, useMemo, useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { config_url } from "../../config";
import SelectOpt from "react-select";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {
  RiAddCircleFill,
  RiDeleteBin6Fill,
  RiEdit2Line,
  RiEyeOffLine,
  RiEyeLine,
  RiCheckDoubleFill,
  RiCloseLargeFill,
} from "react-icons/ri";
import { Tooltip, Typography } from "@mui/material";
import axios from "axios";

function Fornisseur() {
  const { User } = useSelector((state) => state.userInfo);
  const [listProds, setListProds] = useState([]);
  const [listProdsNotValid, setListProdsNotValid] = useState([]);
  const [listFiltred, setListFiltred] = useState([]);
  console.log(listProdsNotValid);

  const [disable_button, setDisable] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTableProdNotValid, setShowTableProdNotValid] = useState(false);
  const [rowProduit, setRowProduit] = useState({});

  const [category, setSelectCategory] = useState("Tous");
  const [selectVille, setSelectVille] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  console.log(selectedCategories);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  console.log(rowSelectionModel);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${config_url}/api/products`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then(async (res) => {
        await setListProds(res.data);
        await setListFiltred(res.data);
      });
  }, []);

  useEffect(() => {
    setDisable(rowSelectionModel.length === 0);
  }, [rowSelectionModel]);
  const deleteEmployee = (id, image, images) => {
    axios
      .post(
        `${config_url}/api/product/${id}`,
        {
          image: image,
          images: images,
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then(() => {
        setListFiltred(listFiltred.filter((row) => row.id !== id));
      });
  };
  const hideninstock = (id) => {
    axios
      .patch(
        `${config_url}/api/hiden_in_stock/${id}`,
        {
          hiden_in_stock: 1,
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then(() => {
        toast.success("Hiden In Stock Product !!", {
          position: "top-right",
        });
        setTimeout(() => {
          window.location.reload(false);
        }, "4000");
      });
  };
  function popup(id, fname, image, images) {
    Swal.fire({
      title: "Êtes vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, supprimez " + fname + " ",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEmployee(id, image, images);
        Swal.fire("Supprimé!", "Produit a été supprimé.", "success");
      }
    });
  }
  function HidenInStock(id, fname) {
    Swal.fire({
      title: "Êtes vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#D2691E",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, Hiden In Stock " + fname + " ",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        Swal.fire("Produit " + fname + " est en Hiden In Stock", {
          icon: "success",
        });
        hideninstock(id);
      } else {
        Swal.fire("product en stock!");
      }
    });
  }

  const handleCloture = async () => {
    try {
      const response = await axios.post(`${config_url}/api/cloture`);
      alert(response.data);
    } catch (error) {
      console.error("There was an error updating the stock!", error);
    }
  };

  function popupCloture() {
    Swal.fire({
      title: "Êtes vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText:
        "Oui, Retour Tout Valeur des produits en stock initial",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCloture();
        Swal.fire(
          "Mise a jour!",
          "Tout Valeur des produits en stock initial",
          "success"
        );
      }
    });
  }
  const handleSubmitCheck = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${config_url}/api/cloture/check_probleme`
      );
      if (response.data.valid) {
        toast.success("Stock is valid!");
        setShowTableProdNotValid(false);
        popupCloture();
      } else {
        toast.error("Stock is not valid!");
        setShowTableProdNotValid(true);
        setListProdsNotValid(response.data.data);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      toast.error("An error occurred!");
    }
  };

  //Filters

  const selOptions = [];
  const ids = listFiltred?.map((o) => o.name_es);
  const filtered = listFiltred?.filter(
    ({ name_es }, index) => !ids?.includes(name_es, index + 1)
  );

  for (let i = 0; i < filtered.length; i++) {
    if (filtered.length > 0) {
      selOptions.push({
        value: filtered[i].name_es,
        label: filtered[i].name_es,
        id: filtered[i].id,
      });
    }
  }

  const columns = [
    {
      field: "name",
      headerName: "Product:",
      headerClassName: "super-app-theme--cell",

      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-line", overflow: "hidden" }}>
            <Typography>{params.row.name}</Typography>
          </div>
        );
      },
    },
    {
      field: "stock_initiale",
      headerName: "Qte Initial:",
      headerClassName: "super-app-theme--cell",

      width: 140,
    },
    {
      field: "prix",
      headerName: "Price:",
      headerClassName: "super-app-theme--cell",

      width: 100,
    },
    {
      field: "unity",
      headerName: "Unite",
      headerClassName: "super-app-theme--cell",

      width: 120,
    },
    {
      field: "date_dernier_stockage",
      headerName: "Date Stockage",
      headerClassName: "super-app-theme--cell",

      width: 120,
    },
    {
      field: "out-stock",
      headerName: "In Stock:",
      headerClassName: "super-app-theme--cell",

      width: 80,
      renderCell: (params) => {
        return (
          <Tooltip title="Check In Stock" placement="top">
            <div className="mt-2">
              {params.row.out_stock === 1 ? (
                <RiCheckDoubleFill className="collabListEdit" />
              ) : (
                <RiCloseLargeFill className="collabListDelete" />
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "image",
      headerName: "Image :",
      headerClassName: "super-app-theme--cell",

      width: 100,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundImage: `url(${config_url}/produits/${params.row.image})`,
              width: "50px",
              height: "50px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        );
      },
    },
    {
      field: "modification",
      headerName: "Modifications",
      width: 340,
      renderCell: (params) => {
        return (
          <div className="mt-2 flex items-center justify-center gap-4">
            {User.role === "Super-Admin" && (
              <>
                <div>
                  <RiEdit2Line className="collabListEdit" />
                </div>
                <div>
                  <RiDeleteBin6Fill
                    className="collabListDelete"
                    onClick={() => {
                      popup(
                        params.row.id,
                        params.row.name_es,
                        params.row.image,
                        params.row.images
                      );
                    }}
                  />
                </div>
              </>
            )}
            <div>
              <Tooltip title="Hiden" placement="top">
                <div>
                  <RiEyeOffLine
                    className="collabListEye"
                    onClick={() => {
                      HidenInStock(params.row.id, params.row.name_es);
                    }}
                  />
                </div>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Informations Sur Produirt" placement="top">
                <div>
                  <RiEyeLine className="collabListEdit" />
                </div>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <Fragment>
      <div className="page__main">
        <h1>List Products:</h1>

        <Fragment>
          <Box
            sx={{
              height: "auto",
              width: "90vw",
              "& .super-app-theme--cell": {
                backgroundColor: "#fff",
                color: "#1a3e72",
                fontWeight: "bold",
              },
              boxShadow: 2,
              border: 2,
              borderRadius: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          >
            <DataGrid
              rows={listFiltred}
              columns={columns}
              getRowId={(row) => row.id}
            />
          </Box>
        </Fragment>
      </div>
    </Fragment>
  );
}

export default Fornisseur;
