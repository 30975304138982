import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProductCard from "../../components/product-card/ProductCard";
import { config_url } from "../../config";
import { useTranslation } from "react-i18next";
import ReactImageGallery from "react-image-gallery";
import Button from "@mui/material/Button";
import { MdArrowBack } from "react-icons/md";
import Zoom from "react-medium-image-zoom";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-medium-image-zoom/dist/styles.css";
import { useNavigate } from "react-router-dom";
import "./productdetails.css";
import Navbar from "../../components/Navbar/Navbar";
import WithCommanded from "../../components/product-card/WithCommanded";
import axios from "axios";

const ProductDetail = () => {
  const details = useSelector((state) => state.Load);
  const { Details, Col } = details;
  const { Langue } = details;

  const [productRelations, setProductRelations] = useState([]);
  const [listFiltered, setListFiltered] = useState([]);
  const [withCommande, setWithCommande] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const commanded = Col.filter((item) => item.category === "drinks");
    setWithCommande(commanded);
    const newItems = Col.filter((item) => item.category === Details.category);
    setListFiltered(newItems);
  }, [Details, Col]);

  useEffect(() => {
    axios
      .get(`${config_url}/api/related-products/${Details.id}`)
      .then((res) => {
        setProductRelations(res.data);
      });
  }, [Details]);

  const imagesArray = JSON.parse(Details.images) || [];
  const validImages = [Details.image, ...imagesArray].filter((image) => image);

  const productDetailItem = {
    images: validImages.map((image) => ({
      original: `${config_url}/images/${image}`,
      thumbnail: `${config_url}/images/${image}`,
    })),
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const renderItem = (item) => (
    <div className="image-gallery-image">
      <Zoom>
        <img src={item.original} alt="" />
      </Zoom>
    </div>
  );

  const propertyName = `${Details.name}_${Langue.lang}`;
  const nameLang = Details[propertyName];

  const propertyDescName = `${Details.description}_${Langue.lang}`;
  const descriptionLang = Details[propertyDescName];

  const propertyIngredName = `${Details.ingredients}_${Langue.lang}`;
  const ingredientsLang = Details[propertyIngredName];

  const propertyAllergName = `${Details.allergies}_${Langue.lang}`;
  const allergiesLang = Details[propertyAllergName];

  return (
    <Fragment>
      <Navbar />
      <section className="container flex flex-col mx-auto py-5 lg:grid lg:grid-cols-2 lg:py-10">
        <div className="flex flex-col mx-auto justify-center items-center">
          <Button
            onClick={() => navigate("/Pos/menu/category")}
            variant="contained"
            startIcon={<MdArrowBack />}
          >
            {t("Detail.back-to-menu")}{" "}
          </Button>
          <h2 className="flex-start pt-3 text-2xl font-bold lg:pt-0">
            {nameLang}
          </h2>
          <div className="container px-4 my-4">
            <ReactImageGallery
              showFullscreenButton={false}
              showPlayButton={false}
              renderItem={renderItem}
              autoPlay={false}
              lazyLoad={true}
              items={productDetailItem.images}
            />
          </div>
          {Details.video && (
            <div className="flex flex-col ml-10">
              <span className="font-bold text-black mb-5 mr-8 mt-10">
                Video Product:
              </span>
              <div className="video-container">
                <video key={Details.video} width="250" height="400" controls>
                  <source
                    src={`${config_url}/videos/${Details.video}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )}
          <div className="mx-auto mt-10 px-5 lg:px-5">
            <div className="mt-1"></div>
            <p className="mt-4 text-4xl font-bold text-violet-900">
              {Details.prix_promo === 0 ? "" : `${Details.prix_promo} Dh`}
              {Details.prix_promo === 0 ? (
                <span className="mt-4 text-4xl font-bold text-violet-900">
                  {Details.prix} Dh
                </span>
              ) : (
                <span className="ml-2 text-xl text-gray-400 line-through">
                  {Details.prix} Dh
                </span>
              )}
            </p>
            <p className="pt-5 text-xl leading-6 text-black">
              {descriptionLang}
            </p>
            {ingredientsLang && (
              <p className="pt-8 text-sm font-semibold leading-5 text-gray-500">
                <span className="text-blue-500 font-bold underline">
                  {t("Detail.produit-ingredients")}:
                </span>
                <span className="ml-1">{ingredientsLang}</span>
              </p>
            )}
            {allergiesLang && (
              <p className="pt-10 text-sm font-semibold leading-5 text-gray-500">
                <span className="text-blue-500 font-bold underline">
                  {t("Detail.produit-allergies")}:
                </span>
                <span className="ml-1">{allergiesLang}</span>
              </p>
            )}
          </div>
        </div>
      </section>
      {productRelations.length > 0 && (
        <>
          <h1 className="mt-10 font-bold text-black text-2xl">
            {t("Detail.produit-commanded")}
          </h1>
          <div className="flex flex-wrap text-red-500">
            {productRelations.map((item) => (
              <WithCommanded key={item.id} item={item} />
            ))}
          </div>
        </>
      )}
      <h1 className="mt-10 font-bold text-black text-2xl">
        {t("Detail.produit-similaire")}
      </h1>
      <div className="mb-[50%] flex flex-wrap text-red-500">
        {listFiltered.map((item) => (
          <ProductCard key={item.id} item={item} />
        ))}
      </div>
    </Fragment>
  );
};

export default ProductDetail;
