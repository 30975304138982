import React, { useState, Fragment } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { config_url } from "../../config";
import { styled } from "@mui/material/styles";

import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import {
  MdCloudUpload,
  MdDelete,
  MdOutlineFilter,
  MdVideoLibrary,
  MdEditDocument,
} from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import { RiCloseLargeFill } from "react-icons/ri";
import { Typography } from "@mui/material";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
function UpdProdRelations() {
  const Detail = useSelector((state) => state.Load);
  const { Col } = Detail;
  const [products_related, setProductsRelated] = useState(Col.related_products);

  const handleDelete = (chipToDelete, product_id) => async () => {
    await popup(product_id, chipToDelete.related_id, chipToDelete);
  };
  function popup(product_id, related_id, chipToDelete) {
    Swal.fire({
      title: "Êtes vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, supprimez Le Relation !!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(
          `${config_url}/api/product-relations/${product_id}/${related_id}`
        );
        Swal.fire("Supprimé!", "Relation a été supprimé.", "success");
        setProductsRelated((chips) =>
          chips.filter((chip) => chip.related_id !== chipToDelete.related_id)
        );
      }
    });
  }

  return (
    <div className="flex flex-col  items-center justify-center gap-8">
      <h3>Product: {Col.product_name}</h3>
      <Box>
        <Typography>Related With Products:</Typography>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          {products_related.map((data) => {
            let icon;

            return (
              <ListItem key={data.related_id}>
                <Chip
                  icon={icon}
                  label={data.related_product_name}
                  onDelete={handleDelete(data, Col.id)}
                />
              </ListItem>
            );
          })}
        </Paper>
      </Box>
    </div>
  );
}

export default UpdProdRelations;
