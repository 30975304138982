import React, { useState, useEffect, Fragment } from "react";
import { CheckCircle, Clock, ShoppingBag, Wallet } from "lucide-react";
import StatCard from "../../components/common/StatCard";
import { motion } from "framer-motion";

import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Grid } from "@mui/material";
import { config_url } from "../../config";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import DailyOrders from "../../components/orders/DailyOrders";

// Colors for Pie Chart
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const ListOrders = () => {
  const [orders, setOrders] = useState([]);
  const [statusOrder, setStatusOrder] = useState({});
  const [departmentData, setDepartmentData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [orderStats, setOrderStats] = useState([]);
  const [dataProblems, setDataProblems] = useState([]);
  const [TopProductCombinations, setTopProductCombinations] = useState([]);
  console.log(TopProductCombinations);
  useEffect(() => {
    // Fetch the orders from the API
    axios
      .get(`${config_url}/api/orders/dashboard`)
      .then((response) => {
        setOrders(response.data.orders);
        generateStatistics(response.data.orders); // Generate statistics for the charts
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);
  useEffect(() => {
    // Fetch the orders from the API
    axios
      .get(`${config_url}/api/statistics-all/orders`)
      .then((response) => {
        setStatusOrder(response.data);
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);
  // Generate statistics for charts
  const generateStatistics = (orders) => {
    // Count orders by department
    const departmentCount = orders.reduce((acc, order) => {
      acc[order.department] = (acc[order.department] || 0) + 1;
      return acc;
    }, {});

    const departmentDataArray = Object.keys(departmentCount).map((key) => ({
      name: key,
      value: departmentCount[key],
    }));

    // Count orders by status
    const statusCount = orders.reduce((acc, order) => {
      acc[order.status] = (acc[order.status] || 0) + 1;
      return acc;
    }, {});

    const statusDataArray = Object.keys(statusCount).map((key) => ({
      name: key,
      count: statusCount[key],
    }));

    setDepartmentData(departmentDataArray);
    setStatusData(statusDataArray);
  };
  useEffect(() => {
    axios
      .get(`${config_url}/api/orders-dash/stats`)
      .then((response) => {
        setOrderStats(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    // Fetch data from the backend
    axios
      .get(`${config_url}/api/problems/stats`)
      .then((response) => {
        setDataProblems(response.data); // Set the response data to state
      })
      .catch((error) => {
        console.error("Error fetching problem stats:", error);
      });
  }, []);
  useEffect(() => {
    // Fetch the data from the Express.js API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config_url}/api/top-products-combination`
        ); // Replace with the correct API endpoint
        const result = await response.json();
        console.log(result);
        // Transform the data to group other_department_product
        const transformedData = result.reduce((acc, item) => {
          const existing = acc.find(
            (row) => row.pret_product === item.pret_product
          );
          if (existing) {
            existing.other_department_product.push(
              item.other_department_product[0]
            );
          } else {
            acc.push({
              pret_product: item.pret_product,
              pret_orders_count: item.pret_orders_count,
              other_department_product: [item.other_department_product[0]],
              other_department_orders_count: item.other_department_orders_count,
            });
          }
          return acc;
        }, []);

        setTopProductCombinations(transformedData);
      } catch (error) {
        console.error("Error fetching top product combinations:", error);
      }
    };

    fetchData();
  }, []);
  // Define columns for DataGrid
  const columns = [
    {
      field: "order_num",
      headerName: "Order Number",
      headerClassName: "super-app-theme--cell",
      width: 120,
    },
    {
      field: "date_order",
      headerName: "Date",
      headerClassName: "super-app-theme--cell",
      width: 150,
    },
    {
      field: "qty",
      headerName: "Quantity",
      headerClassName: "super-app-theme--cell",
      width: 80,
    },
    {
      field: "total_price",
      headerName: "Total Price",
      headerClassName: "super-app-theme--cell",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--cell",
      width: 100,
    },
    {
      field: "plat_name",
      headerName: "Plat Name",
      headerClassName: "super-app-theme--cell",
      width: 340,
    },
    {
      field: "waiter_name",
      headerName: "Waiter Name",
      headerClassName: "super-app-theme--cell",
      width: 160,
    },
    {
      field: "department",
      headerName: "Department",
      headerClassName: "super-app-theme--cell",
      width: 130,
    },
    {
      field: "table_num",
      headerName: "Table Number",
      headerClassName: "super-app-theme--cell",
      width: 100,
    },
  ];

  return (
    <Fragment>
      <h1>Orders:</h1>
      <Box
        sx={{
          height: "auto",
          width: "80vw",
          flex: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .super-app-theme--cell": {
            backgroundColor: "#fff",
            color: "#1a3e72",
            fontWeight: "bold",
          },
          boxShadow: 2,
          border: 2,
          borderRadius: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      >
        <DataGrid
          rows={orders}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </Box>
      <main className="max-w-10xl mx-auto py-6 px-4 lg:px-8">
        <motion.div
          className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <StatCard
            name="Total Orders"
            icon={ShoppingBag}
            value={statusOrder.total_orders}
            color="#6366F1"
          />
          <StatCard
            name="Total  sales Dh"
            icon={Wallet}
            value={statusOrder.total_sales}
            color="#F59E0B"
          />
          <StatCard
            name="Total Qty Sales"
            icon={CheckCircle}
            value={statusOrder.total_qty_sales}
            color="#10B981"
          />
          <StatCard
            name="Total Revenue Dh"
            icon={Wallet}
            value="230"
            color="#EF4444"
          />
        </motion.div>
      </main>
      {/* Recharts Visualization */}
      <h2>Order Statistics</h2>
      <Grid container spacing={2}>
        {/* Pie Chart for Orders by Department */}
        <Grid item xs={12} md={6}>
          <h3>Orders by Department</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={departmentData}
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                label
              >
                {departmentData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <BarChart width={600} height={300} data={orderStats}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="department" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="total_qty" fill="#8884d8" />
            <Bar dataKey="total_sales" fill="#82ca9d" />
          </BarChart>
        </Grid>
      </Grid>

      <DailyOrders />
      <div style={{ width: "100%", height: 400 }}>
        <h2>Problem Statistics</h2>
        <ResponsiveContainer>
          <BarChart
            data={dataProblems}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="problem" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="occurrences" fill="#8884d8" />
            <Bar dataKey="total_loss" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      {/* <div style={{ width: "100%", height: 500 }}>
        <h2>Top Product Combinations (Pret vs Other Departments)</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm">
                <th className="py-2 px-4 border">Pret Product</th>
                <th className="py-2 px-4 border">Pret Orders Count</th>
                <th className="py-2 px-4 border">Other Department Products</th>
                <th className="py-2 px-4 border">
                  Other Department Orders Count
                </th>
              </tr>
            </thead>
            <tbody>
              {TopProductCombinations.map((item, index) => (
                <tr key={index} className="text-gray-700 text-sm">
                  <td className="py-2 px-4 border">{item.pret_product}</td>
                  <td className="py-2 px-4 border">{item.pret_orders_count}</td>
                  <td className="py-2 px-4 border">
                    {item.other_department_product.join(", ")}
                  </td>
                  <td className="py-2 px-4 border">
                    {item.other_department_orders_count}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}
    </Fragment>
  );
};

export default ListOrders;
