import React, { Fragment, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { toast } from "react-toastify";
import { config_url } from "../config";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

function ModelUpdTable({ closeModal, setTableNum, tableNum, orderNum }) {
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const response = await axios.patch(
          `${config_url}/api/orders/change_table_num/${orderNum}`,
          {
            table_num: tableNum,
          }
        );
        toast.success("Table number updated successfully!");
        closeModal();
      } catch (error) {
        toast.error("Failed to update table number.");
      } finally {
        setLoading(false);
      }
    }, 2000); // Simulate a delay of 2 seconds
  };

  const handleInputChange = (e) => {
    setTableNum(e.target.value);
    setIsChanged(true);
  };

  return (
    <Fragment>
      <Modal
        open={true}
        onClose={() => closeModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-full bg-gray-100 p-2 mb-4">
            <Typography variant="h5" gutterBottom>
              Changer Numero Table
            </Typography>
          </div>
          <Divider />
          <div className="mt-4 flex items-center justify-center justify-between">
            <Box sx={{ ml: 2, minWidth: 220 }}></Box>
            <FormControl sx={{ m: 1 }} variant="standard">
              <Input
                onChange={handleInputChange}
                defaultValue={tableNum}
                id="standard-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">Num</InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className="mt-10">
            {isChanged && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Apply Changes"}
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default ModelUpdTable;
