import React, { Fragment, useState, useEffect } from "react";
import "./product-card.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { config_url } from "../../config";
import { loadDetailsProduct } from "../../slices/detailsProduct";
import {
  selectCartItems,
  selectCartState,
  selectTotalAmount,
  selectTotalQTY,
  setClearCartItems,
  setCloseCart,
  setGetTotals,
  selectTable,
  chooiceTableReserved,
} from "../../slices/cartSlice.js";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { AiOutlineClose } from "react-icons/ai";
import ReservationModel from "../reservation/ReservationModel.jsx";
import _ from "lodash"; // Import lodash for debounce

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "80%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: "12px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ProductCard = (props) => {
  const det = useSelector((state) => state.Load);
  const { Langue } = det;
  const [tables, setTables] = useState([]);
  const [open, setOpen] = useState(false);
  const [openReservation, setOpenReservation] = useState(false);
  const [block, setBlock] = useState(false);
  const [searchTable, setSearchTable] = useState("");
  const [filteredTables, setFilteredTables] = useState(props.tables);

  const { plat_name, plat_image, plat_prix, plat_prix_promo } = props.item;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const details = (dts) => {
    console.log(dts);
    if (location.pathname === "/Pos/menu/category") {
      // Save the current scroll position
      localStorage.setItem("scrollPosition", window.scrollY);
      localStorage.setItem("categorie_filtered", dts.category);
    }

    dispatch(loadDetailsProduct(dts));

    navigate("/Pos/menu/category/details");
  };
  const ReservedTable = (table) => {
    dispatch(chooiceTableReserved({ id: table.id, name: table.numero }));
    setBlock(true);
    setOpen(!open);
    setOpenReservation(!openReservation);
  };
  const { t } = useTranslation();
  useEffect(() => {
    // Check if the current path is `/Pos/menu/category`
    if (location.pathname === "/Pos/menu/category") {
      // Retrieve scroll position from localStorage
      const savedPosition = localStorage.getItem("scrollPosition");
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition, 10));
        localStorage.removeItem("scrollPosition"); // Clean up after restoring
      }
    }
  }, [location.pathname]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenReservation = () => setOpenReservation(true);
  const handleCloseReservation = () => setOpenReservation(false);

  const handleSearch = _.debounce((term) => {
    const filtered = props.tables.filter((table) => {
      const tableString = String(table.numero || "").toLowerCase();
      return tableString.includes(term.toLowerCase());
    });
    setFilteredTables(filtered);
  }, 300); // Debounce delay in milliseconds

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTable(value);
    handleSearch(value);
  };

  return (
    <Fragment>
      {openReservation && (
        <ReservationModel
          openReservation={openReservation}
          setOpenReservation={setOpenReservation}
          handleCloseReservation={handleCloseReservation}
          handleOpenReservation={handleOpenReservation}
        />
      )}
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <AiOutlineClose
                onClick={() => handleClose()}
                className="fixed right-2 top-2 text-3xl text-white bg-red-500 rounded p-1"
              />
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Numero Table"
                  variant="outlined"
                  value={searchTable}
                  onChange={(e) => handleChange(e)} // Update search term and filter dynamically
                />
              </Box>

              <div className="grid items-center grid-cols-2 gab-8">
                {filteredTables?.map((item) => (
                  <div
                    className="m-4 max-w-sm rounded overflow-hidden shadow-lg cursor-pointer"
                    key={item.id}
                    // onClick={() => ReservedTable(item)}
                  >
                    <img
                      className="w-full"
                      src="/images/table.webp"
                      alt="Sunset in the mountains"
                    />
                    <div className="px-6 py-4">
                      <div className="font-bold text-xl mb-2">
                        Num Table:{item.numero}
                      </div>
                    </div>

                    <div className="px-6 pt-4 pb-2">
                      <button
                        className="inline-block bg-red-400 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                        onClick={() => ReservedTable(item)}
                      >
                        Reserver
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
      <div className="card_products product-card">
        <div className="single__product">
          <div className="product__img">
            <img src={`${config_url}/plats/${plat_image}`} alt={plat_name} />
          </div>

          <div className="product__content">
            <h4 className="w-full mb-2 mt-2">{plat_name}</h4>
            {plat_prix_promo === 0 ? (
              <div className="flex items-center justify-between inset-x-0 bottom-0">
                <span className="price flex items-center justify-between">
                  <span>{t("Card.prix")}:</span>{" "}
                  <span className="ml-1 font-bold text-xl">{plat_prix}DH</span>
                </span>
              </div>
            ) : (
              <div className="items-center text-center">
                <div className="flex items-center justify-between inset-x-0 bottom-0">
                  <span className="text-[#EAE0DA] text-xl font-bold flex items-center ml-10">
                    {t("Card.prix")}:{" "}
                    <span className="promotion"> {plat_prix}DH</span>
                  </span>
                </div>
                <div className="flex items-center justify-between inset-x-0 bottom-0">
                  <span className="text-white text-center">
                    {t("Card.prix_promo")}:
                    <span className="text-white font-bold text-xl">
                      {plat_prix_promo} DH
                    </span>
                  </span>
                </div>
              </div>
            )}

            <button className="product__detail" onClick={handleOpen}>
              <span> Select Table</span>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductCard;
