import React, { useState, Fragment } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { config_url } from "../../config";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { MdCloudUpload, MdDelete, MdEditDocument } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
function UpdProduit() {
  const Detail = useSelector((state) => state.Load);
  const { Col } = Detail;

  const [category, setSelectCategory] = useState(Col.category);

  const [name, setNom] = useState(Col.name);
  const [prix, setPrix] = useState(Col.prix);
  const [fornisseur, setFornisseur] = useState(Col.fornisseur);
  const [stock_initiale, setStockInitiale] = useState(Col.stock_initiale);
  const [stock_restant, setStockRestant] = useState(Col.stock_restant);
  const [date_dernier_stockage, setDateDernierStockage] = useState(
    Col.date_dernier_stockage
  );

  const [description, setDescription] = useState(Col.description);
  const [out_of_stock, setOut] = useState(Col.out_stock);
  const [menu_enfant, setMenuEnfant] = useState(Col.enfant);

  const [image, setImage] = useState(`${config_url}/produits/${Col.image}`);
  const [oldImage, setOldImage] = useState(Col.image);
  const [upload_image, setUploadedImage] = useState(false);
  const [fileName, setFileName] = useState("No selected file");
  const [unity, setUnity] = useState("kg");

  const [loading, setLoading] = useState(false);

  const handleUpload = async (e) => {
    e.preventDefault();
    await setLoading(true);

    const formdata = new FormData();
    formdata.append("image", image);
    formdata.append("oldimage", oldImage);
    formdata.append("upload_image", upload_image);

    formdata.append("name", name);
    formdata.append("prix", prix);
    formdata.append("unity", unity);
    formdata.append("fornisseur", fornisseur);

    formdata.append("description", description);
    formdata.append("stock_initial", stock_initiale);
    formdata.append("stock_restant", stock_restant);
    formdata.append("date_dernier_stockage", date_dernier_stockage);
    formdata.append("out_of_stock", out_of_stock);

    try {
      const response = await axios.put(
        `${config_url}/api/update-prod/${Col.id}`,

        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200)
        toast.success("Changement Produit Success !!", {
          position: "top-right",
        });
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSelectUnity = (e) => {
    setUnity(e.target.value);
  };
  const handleSelectOutOfStock = (e) => {
    setOut(e.target.value);
  };
  const handleSelectMenuEnfant = (e) => {
    setMenuEnfant(e.target.value);
  };
  const handleImage = (file) => {
    setUploadedImage(true);
    setImage(file);
  };

  return (
    <Fragment>
      <div className="page__main">
        <div className="ml-[10%]">
          <h1>
            Change Information About:{" "}
            <span className="text-red-400 font-sans"> {Col.name}</span>
          </h1>
          <form
            onSubmit={handleUpload}
            className="p-8 text-xl w-full bg-white rounded-3xl"
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Nom Produit:
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                  defaultValue={Col.name}
                  onChange={(e) => setNom(e.target.value)}
                  placeholder="Name Product:"
                />
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Price:
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  type="number"
                  step="any"
                  defaultValue={Col.prix}
                  onChange={(e) => setPrix(e.target.value)}
                  placeholder="Price:"
                />
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Stock Initiale:
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  type="number"
                  step="any"
                  defaultValue={Col.stock_initiale}
                  onChange={(e) => setStockInitiale(e.target.value)}
                  placeholder="Valeur Stock Initiale:"
                />
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Fornisseur:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-sky-500"
                  type="text"
                  name="fornisseur"
                  defaultValue={Col.fornisseur}
                  onChange={(e) => setFornisseur(e.target.value)}
                  placeholder="Fornisseur De Produit:"
                />
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Date Dernier Stockage:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-sky-500"
                  type="date"
                  defaultValue={Col.date_dernier_stockage}
                  onChange={(e) => setDateDernierStockage(e.target.value)}
                />
              </div>

              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Stock Restant:
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  type="number"
                  step="any"
                  defaultValue={Col.stock_restant}
                  onChange={(e) => setStockRestant(e.target.value)}
                  placeholder="Valeur Stock Restant:"
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="w-90 px-3 mb-6 md:mb-0">
                <Box sx={{ ml: 2, minWidth: 220 }}>
                  <FormControl sx={{ minWidth: 220 }}>
                    <label className="mt-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Unity:
                    </label>{" "}
                    <Select
                      value={unity}
                      onChange={handleSelectUnity}
                      sx={{ backgroundColor: "#fff" }}
                    >
                      <MenuItem value="kg">Kg</MenuItem>
                      <MenuItem value="l">L</MenuItem>
                      <MenuItem value="unity">Unite</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="w-90 flex flex-col items-center px-3">
                <label className="ml-12 mb-2 block text-lg font-bold text-black">
                  Out Of Stock:
                </label>

                <select
                  className="ml-10"
                  value={out_of_stock}
                  onChange={handleSelectOutOfStock}
                >
                  <option value="1">In Stock</option>
                  <option value="0">Out Stock</option>
                </select>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6 p-10">
              <div className="grid gap-4">
                <div
                  className="form"
                  onClick={() => document.querySelector(".input-field").click()}
                >
                  <input
                    type="file"
                    className="input-field"
                    hidden
                    onChange={({ target: { files } }) => {
                      files[0] && setFileName(files[0].name);
                      if (files) {
                        handleImage(files[0]);
                      }
                    }}
                  />

                  {image ? (
                    <img
                      className="rounded-full"
                      src={
                        typeof image === "string"
                          ? image
                          : URL.createObjectURL(image)
                      }
                      width={150}
                      height={150}
                      alt={fileName}
                      name="image"
                    />
                  ) : (
                    <>
                      <MdCloudUpload color="#1475cf" size={60} />
                      <p>Browse Files to upload</p>
                    </>
                  )}
                </div>

                <section className="w-90 uploaded-row">
                  <AiFillFileImage color="#1475cf" />
                  <span className="upload-content">
                    {fileName} -
                    <MdDelete
                      onClick={() => {
                        setFileName("No selected File");
                        setImage(null);
                      }}
                    />
                  </span>
                </section>
              </div>
            </div>
            <div className="flex-start">
              <h1 className="text-center">Description Of Product:</h1>
              <div className="bg-gray-100 p-3 rounded-2xl flex flex-wrap -mx-3 mb-6 mx-auto max-w-[800px]">
                <div className="ml-10 w-90">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Description:
                  </label>{" "}
                  <textarea
                    type="text"
                    defaultValue={Col.description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="resize rounded-md border-2 border-solid border-gray-200 focus:outline-none focus:border-sky-400"
                    rows="5"
                    cols="30"
                  ></textarea>
                </div>
              </div>
            </div>
            <button
              className="flex items-center gap-2 bg-green-500 hover:bg-white hover:text-green-400 hover:border-dashed hover:border-4 text-white font-bold py-2 px-4 rounded-full"
              type="submit"
            >
              {loading && <i className="fa fa-refresh fa-spin"></i>}

              <span>Change</span>
              <span>
                <MdEditDocument />
              </span>
            </button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdProduit;
