import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useReactToPrint } from "react-to-print";
import { MdDeleteForever } from "react-icons/md";

export default function Invoice({
  closeInvoice,
  invoiceShow,
  rowOrders,
  filteredOrders,
  setFilteredOrders,
}) {
  const [listFiltered, setListFiltered] = useState([]);
  const [splitInvoices, setSplitInvoices] = useState([]); // Split the invoices
  console.log(splitInvoices);
  const [displayCount, setDisplayCount] = useState(1); // Number of times to display
  const [totalPricesPerInvoice, setTotalPricesPerInvoice] = useState([]); // Store total prices for each invoice

  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [open, setOpen] = useState(true);
  // State to manage quantities
  const [quantities, setQuantities] = useState({});
  const [quantitiesPerInvoice, setQuantitiesPerInvoice] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null); // State for managing hover

  const componentRef = useRef();
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (rowOrders && filteredOrders) {
      const filtered = filteredOrders.filter(
        (order) => order.order_num === rowOrders.order_num
      );
      setListFiltered(filtered);

      // Split filtered orders into displayCount equal parts
      const splitOrders = [];
      for (let i = 0; i < displayCount; i++) {
        splitOrders.push([...filtered]); // Push the same filtered array into splitOrders
      }

      setSplitInvoices(splitOrders);

      // Initialize quantities based on filtered orders
      const initialQuantities = splitOrders.map((invoice) => {
        const invoiceQuantities = {};
        invoice.forEach((order) => {
          invoiceQuantities[order.id] = order.qty || 1; // Default to 1 if quantity is undefined
        });
        return invoiceQuantities;
      });

      setQuantitiesPerInvoice(initialQuantities);

      // Calculate total prices for each invoice
      const initialTotalPrices = splitOrders.map((invoice) => {
        return invoice.reduce(
          (sum, order) => sum + order.total_price * (order.qty || 1),
          0
        );
      });

      setTotalPricesPerInvoice(initialTotalPrices);
    }
  }, [rowOrders, filteredOrders, displayCount]);
  // Update quantitiesPerInvoice when splitInvoices changes
  useEffect(() => {
    setQuantitiesPerInvoice(
      splitInvoices.map((invoice) => {
        return invoice.reduce((acc, curr) => {
          if (curr?.id) {
            acc[curr.id] = curr.qty || 1; // Default quantity to 1 if curr.qty is undefined
          }
          return acc;
        }, {});
      })
    );
  }, [splitInvoices]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintAndFilter = () => {
    handlePrint();
    setFilteredOrders((prevOrders) =>
      prevOrders.filter((item) => item.order_num !== rowOrders.order_num)
    );
  };

  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatTime = (date) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleTimeString("en-US", options);
  };

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      setCurrentDate(formatDate(now));
      setCurrentTime(formatTime(now));
    };

    updateDateTime(); // Initial call to set the date/time immediately
    const intervalId = setInterval(updateDateTime, 1000); // Update every second

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  const handleSelectChange = (e) => {
    setDisplayCount(Number(e.target.value));
  };
  // Handle quantity change
  const handleQuantityChange = (invoiceIndex, productId, newQty) => {
    setQuantitiesPerInvoice((prevQuantities) => {
      const updatedQuantities = [...prevQuantities];

      if (updatedQuantities[invoiceIndex]) {
        updatedQuantities[invoiceIndex][productId] = newQty; // Update the quantity
      }

      // Recalculate total price for this specific invoice
      const updatedTotalPrices = [...totalPricesPerInvoice];
      const newTotalPrice = splitInvoices[invoiceIndex].reduce((sum, curr) => {
        const qty = updatedQuantities[invoiceIndex][curr.id] || curr.qty;
        return sum + curr.total_price * qty;
      }, 0);

      updatedTotalPrices[invoiceIndex] = newTotalPrice;
      setTotalPricesPerInvoice(updatedTotalPrices);

      return updatedQuantities;
    });
  };
  const handleDelete = (invoiceIndex, productId) => {
    setSplitInvoices((prevInvoices) =>
      prevInvoices.map((invoice, index) =>
        index === invoiceIndex
          ? invoice.filter((item) => item.id !== productId)
          : invoice
      )
    );

    // Recalculate total price for this specific invoice
    const updatedTotalPrices = [...totalPricesPerInvoice];
    const newTotalPrice = splitInvoices[invoiceIndex]
      .filter((item) => item.id !== productId)
      .reduce((sum, curr) => {
        const qty = quantitiesPerInvoice[invoiceIndex]?.[curr.id] || curr.qty;
        return sum + curr.total_price * qty;
      }, 0);

    updatedTotalPrices[invoiceIndex] = newTotalPrice;
    setTotalPricesPerInvoice(updatedTotalPrices);
  };
  return (
    <div>
      {/* Render the Invoice component based on the selected number */}
      <Transition.Root show={invoiceShow} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeInvoice}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  {splitInvoices?.map((invoice, invoiceIndex) => (
                    <div
                      className="flex flex-row items-center justify-center gap-3"
                      key={invoiceIndex}
                    >
                      <div>
                        <div>
                          <div
                            className="flex flex-col items-center justify-center gap-4"
                            ref={componentRef}
                          >
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src="/DON-CALAMAR.jpg"
                                className="w-20 h-20"
                              />
                            </div>
                            <div>
                              <h3 className="text-xl font-semibold tracking-wider">
                                Serveur: {rowOrders.nom} {rowOrders.prenom}
                              </h3>
                              <small>A taste you'll remember.</small>
                            </div>
                            <div className="flex items-center justify-between">
                              <div className="text-xs">
                                <h6>Rue Addresse</h6>
                                <p>Nador, ST, ZIP Code</p>
                                <p>Phone : +21264848823 Fax:(503)124578</p>
                              </div>
                              <div className="text-xs">
                                <h6 className="flex">
                                  <p className="font-semibold">DATE</p>:{" "}
                                  {currentDate} {currentTime}
                                </h6>
                                <h6 className="flex">
                                  <p className="font-semibold">Ticket</p> :
                                  &nbsp; #{rowOrders.order_num}
                                </h6>
                              </div>
                            </div>
                            <h6 className="text-xs font-bold text-center pt-3">
                              Welcome to Don Calamar
                            </h6>

                            <div className="mt-3 flex flex-col items-center justify-center">
                              <table
                                style={{
                                  fontSize: "11px",
                                }}
                                className="font-normal divide-x divide-y divide-gray-300"
                              >
                                <tr className="divide-x border-t border-gray-200 border-r border-l divide-gray-300">
                                  <th className="px-4 p-1">Item</th>
                                  <th className="px-4 p-1">Quantity</th>
                                  <th className="px-4 p-1">AMOUNT (Dh)</th>
                                </tr>
                                {invoice?.map((curr, i) => (
                                  <tr
                                    key={i}
                                    className="divide-x divide-gray-300 border-b border-gray-200"
                                    onMouseEnter={() => setHoveredIndex(i)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                  >
                                    <td className="px-4 py-1">
                                      {curr.plat_name}
                                    </td>
                                    <td className="text-center px-4 py-1">
                                      <input
                                        type="number"
                                        value={
                                          quantitiesPerInvoice[invoiceIndex]?.[
                                            curr.id
                                          ] || curr.qty
                                        }
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            invoiceIndex,
                                            curr.id,
                                            Number(e.target.value)
                                          )
                                        }
                                        className="w-12 text-center border border-gray-300 rounded"
                                        min="1"
                                      />
                                    </td>
                                    <td className="text-center px-4 py-1">
                                      {(
                                        curr.total_price *
                                        (quantitiesPerInvoice[invoiceIndex]?.[
                                          curr.id
                                        ] || curr.qty)
                                      ).toFixed(2)}{" "}
                                      {hoveredIndex === i && (
                                        <MdDeleteForever
                                          className="text-red-500 cursor-pointer"
                                          onClick={() =>
                                            handleDelete(invoiceIndex, curr.id)
                                          }
                                          size={20}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </div>

                            <div className="flex justify-between text-xs pt-4">
                              <div className="pr-4">
                                <div className="flex space-x-7 ">
                                  <p>TOTAL : </p>
                                  <p>
                                    {totalPricesPerInvoice[
                                      invoiceIndex
                                    ]?.toFixed(2)}{" "}
                                    Dh
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center justify-between pr-7 pt-3">
                              <p></p>
                              <p className="text-xs">
                                Paid: <strong>Espece</strong>.
                              </p>
                            </div>

                            <div className="flex flex-col pt-8 leading-snug">
                              <small>
                                Please Contact for any queries related to the
                                Invoice.
                              </small>
                              <small className="font-medium">
                                THANK YOU FOR YOUR VISIT.
                              </small>
                            </div>
                          </div>

                          <div className="mt-5 xs:mt-6">
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={handlePrintAndFilter}
                            >
                              Print Invoice
                            </button>
                          </div>
                          <div className="mb-4">
                            <label htmlFor="displayCount">
                              Select number of invoices to display:{" "}
                            </label>
                            <select
                              id="displayCount"
                              value={displayCount}
                              onChange={handleSelectChange}
                              className="border rounded p-2"
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
