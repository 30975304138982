import React, { useEffect, useState } from "react";
import { config_url } from "../../config";

const OrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`${config_url}/api/order-details`);
        const data = await response.json();
        console.log(data);
        setOrderDetails(data.orders);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Order Details</h1>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border">Departement</th>
            <th className="py-2 px-4 border">Plat Name</th>
            <th className="py-2 px-4 border">Status</th>
            <th className="py-2 px-4 border">Date Order</th>
            <th className="py-2 px-4 border">Retard Time</th>
            <th className="py-2 px-4 border">Waiter Name</th>
            <th className="py-2 px-4 border">Quantity</th>
            <th className="py-2 px-4 border">Qty Losed</th>
            <th className="py-2 px-4 border">Problem</th>
            <th className="py-2 px-4 border">Date Created</th>
            <th className="py-2 px-4 border">Quantity Trouver</th>
          </tr>
        </thead>
        <tbody>
          {orderDetails.map((order, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="py-2 px-4 border">{order.departement}</td>
              <td className="py-2 px-4 border">{order.plat_name}</td>
              <td className="py-2 px-4 border">{order.status}</td>
              <td className="py-2 px-4 border">{order.date_order}</td>
              <td className="py-2 px-4 border">{order.retard_time}</td>
              <td className="py-2 px-4 border">{order.waiter_name}</td>
              <td className="py-2 px-4 border">{order.qty}</td>
              <td className="py-2 px-4 border">{order.qty_losed}</td>
              <td className="py-2 px-4 border">{order.problem}</td>
              <td className="py-2 px-4 border">{order.date_created}</td>
              <td className="py-2 px-4 border">{order.quantity_trouver}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderDetails;
