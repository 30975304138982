import React, { useState, Fragment, useEffect, useRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SelectOpt from "react-select";
import "react-image-crop/dist/ReactCrop.css";

import { toast } from "react-toastify";
import {
  MdCloudUpload,
  MdDelete,
  MdOutlineFilter,
  MdVideoLibrary,
  MdOutlineSend,
} from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import { RiCloseLargeFill } from "react-icons/ri";
import { config_url } from "../../config";
function AddProduit() {
  const [name, setName] = useState("");
  const [prix, setPrix] = useState(0);

  const [stockInitial, setStockInitial] = useState(0);
  const [stockRestant, setStockRestant] = useState(0);
  const [fornisseur, setFornisseur] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [unity, setUnity] = useState("kg");
  const [dateDernierStock, setDateDernierStockage] = useState(new Date());
  console.log(image);
  const [fileName, setFileName] = useState("No selected file");

  const [loading, setLoading] = useState(false);

  const handleUpload = async (e) => {
    await setLoading(true);

    const formdata = new FormData();
    formdata.append("image_produit", image);

    formdata.append("name", name);
    formdata.append("prix", prix);
    formdata.append("unity", unity);
    formdata.append("fornisseur", fornisseur);

    formdata.append("description", description);
    formdata.append("stock_initial", stockInitial);
    formdata.append("stock_restant", stockRestant);
    formdata.append("date_dernier_stockage", dateDernierStock);

    try {
      const response = await axios.post(
        `${config_url}/api/create-prod`,
        formdata
      );
      if (response.status === 200)
        toast.success("Ajoute Produit Success !!", {
          position: "top-right",
        });
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSelectUnity = (e) => {
    setUnity(e.target.value);
  };
  return (
    <Fragment>
      <div className="page__main">
        <div className="ml-[10%]">
          <button
            type="button"
            className="relative w-full flex justify-center mb-10 items-center px-5 py-2.5 font-medium tracking-wide text-white capitalize bg-cyan-500 rounded-md  focus:outline-none   transition duration-300 transform active:scale-95 ease-in-out"
          >
            <span className="pl-2 mx-1">Page For Add Product</span>
          </button>

          <form
            className="mb-8 p-8 text-xl bg-white rounded-3xl boxShadow"
            onSubmit={handleUpload}
          >
            <h1 className="text-center">Information About Product:</h1>

            <div className="bg-gray-200 p-3 rounded-2xl flex flex-wrap -mx-3 mb-6 mx-auto max-w-[1200px]">
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Name:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-sky-500"
                  type="text"
                  name="nom"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name Product:"
                />
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Prix:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-sky-500"
                  type="numbre"
                  onChange={(e) => setPrix(e.target.value)}
                  placeholder="Prix Product:"
                />
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Fornisseur:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-sky-500"
                  type="text"
                  name="fornisseur"
                  onChange={(e) => setFornisseur(e.target.value)}
                  placeholder="Fornisseur De Produit:"
                />
              </div>

              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Stock initial:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-sky-500"
                  type="number"
                  onChange={(e) => setStockInitial(e.target.value)}
                  placeholder="Stock Initial:"
                />
              </div>

              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Stock Restant:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-sky-500"
                  type="number"
                  onChange={(e) => setStockRestant(e.target.value)}
                  placeholder="Stock Restant:"
                />
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <Box sx={{ ml: 2, minWidth: 220 }}>
                  <FormControl sx={{ minWidth: 220 }}>
                    <label className="mt-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Unity:
                    </label>{" "}
                    <Select
                      value={unity}
                      onChange={handleSelectUnity}
                      sx={{ backgroundColor: "#fff" }}
                    >
                      <MenuItem value="kg">Kg</MenuItem>
                      <MenuItem value="l">L</MenuItem>
                      <MenuItem value="unity">Unite</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="w-90 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Date Dernier Stockage:
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-sky-500"
                  type="date"
                  defaultValue={dateDernierStock}
                  onChange={(e) => setDateDernierStockage(e.target.value)}
                />
              </div>

              {/* <Box sx={{ ml: 2, minWidth: 220 }}>
                <FormControl sx={{ minWidth: 220 }}>
                  <label className="mt-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Menu's Children:
                  </label>{" "}
                  <Select
                    value={enfant}
                    onChange={handleSelectMenuEnfant}
                    sx={{ backgroundColor: "#fff" }}
                  >
                    <MenuItem value="0">No</MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                  </Select>
                </FormControl>
              </Box> */}
            </div>
            <hr className="border-4 rounded-3xl border-sky-800 border-dashed" />

            <h1 className="text-center text-2xl font-semibold">
              Upload Image,Images And Video
            </h1>

            <div className="flex flex-wrap justify-center -mx-3 mb-6 mx-auto max-w-[1400px]">
              <div className="grid gap-4">
                <div
                  className="form"
                  onClick={() => document.querySelector(".input-field").click()}
                >
                  <input
                    type="file"
                    className="input-field"
                    hidden
                    onChange={({ target: { files } }) => {
                      files[0] && setFileName(files[0].name);
                      if (files) {
                        setImage(files[0]);
                      }
                    }}
                  />

                  {image ? (
                    <img
                      className="w-[150px] h-[150px] rounded-full border-2 border-gray-400"
                      src={URL.createObjectURL(image)}
                      width={150}
                      height={150}
                      alt={fileName}
                    />
                  ) : (
                    <>
                      <MdCloudUpload color="#1475cf" size={60} />
                      <p>Principal Image to upload</p>
                    </>
                  )}
                </div>

                <section className="uploaded-row">
                  <AiFillFileImage color="#1475cf" />
                  <span className="upload-content">
                    {fileName} -
                    <MdDelete
                      onClick={() => {
                        setFileName("No selected File");
                        setImage(null);
                      }}
                    />
                  </span>
                </section>
              </div>
            </div>
            <hr className="border-4 rounded-3xl border-sky-800 border-dashed" />
            <div className="flex-start">
              <h1 className="text-center">Description Of Product:</h1>
              <div className="bg-gray-100 p-3 rounded-2xl flex flex-wrap -mx-3 mb-6 mx-auto max-w-[800px]">
                <div className="ml-10 w-90">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Description:
                  </label>{" "}
                  <textarea
                    type="text"
                    onChange={(e) => setDescription(e.target.value)}
                    className="resize rounded-md border-2 border-solid border-gray-200 focus:outline-none focus:border-sky-400"
                    rows="10"
                    cols="70"
                  ></textarea>
                </div>
              </div>
            </div>

            <button
              className="flex items-center gap-2 ml-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              type="submit"
            >
              {loading && <i className="fa fa-refresh fa-spin"></i>}
              <span>Save</span>
              <span>
                <MdOutlineSend />
              </span>
            </button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default AddProduit;
