import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { config_url } from "../../config";
import { chooiceTableReserved } from "../../slices/cartSlice";
import { detailsProduct } from "../../slices/detailsProduct";

import { useDispatch, useSelector } from "react-redux";
import ReservationModel from "../../components/reservation/ReservationModel";
function Tables() {
  const [filteredTables, setFilteredTables] = useState([]);
  const [tables, setTables] = useState([]);
  const [open, setOpen] = useState(false);
  const [openReservation, setOpenReservation] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    axios.get(`${config_url}/api/tables`).then((res) => {
      setTables(res.data);
      setFilteredTables(res.data);
    });
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${config_url}/api/plats`);
        dispatch(detailsProduct(response.data));
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
    localStorage.setItem("categorie_filtered", "all");
  }, [dispatch]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenReservation = () => setOpenReservation(true);
  const handleCloseReservation = () => setOpenReservation(false);

  const ReservedTable = (table) => {
    dispatch(chooiceTableReserved({ id: table.id, name: table.numero }));
    setOpenReservation(!openReservation);
  };

  return (
    <Fragment>
      {openReservation && (
        <ReservationModel
          openReservation={openReservation}
          setOpenReservation={setOpenReservation}
          handleCloseReservation={handleCloseReservation}
          handleOpenReservation={handleOpenReservation}
        />
      )}

      <div className="mb-[50%] flex flex-wrap items-center justify-center text-red-500">
        {filteredTables?.map((item) => (
          <div
            className="m-4 max-w-sm rounded overflow-hidden shadow-lg cursor-pointer"
            key={item.id}
            // onClick={() => ReservedTable(item)}
          >
            <img
              className="w-full"
              src="/images/table.webp"
              alt="Sunset in the mountains"
            />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">
                Num Table:{item.numero}
              </div>
            </div>

            <div className="px-6 pt-4 pb-2">
              <button
                className="inline-block bg-red-400 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                onClick={() => ReservedTable(item)}
              >
                Reserver
              </button>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
}

export default Tables;
